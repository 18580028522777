<main>
  <menu id="menu-left">
    <li (click)="menuOperationOpen = !menuOperationOpen" [class.active]="menuOperationOpen">Details</li>
    <li (click)="menuProtocolOpen = !menuProtocolOpen" [class.active]="menuProtocolOpen">Protokoll (Beta)</li>
  </menu>

  <div id="content-left" class="content" *ngIf="menuOperationOpen || menuProtocolOpen">
    <ul>
      <li id="content-operation" *ngIf="menuOperationOpen">
        <div class="content-header" (click)="menuOperationOpen = false">Details</div>
        <div class="content-body">
          <h1 [style.border-color]="operation?.category_color ?? '#ddd'">{{ operation?.title }}</h1>
          <div id="time">{{ (operation?.time_start ?? 0) * 1000 | date: 'dd.MM.yyyy HH:mm' }} - <span
            *ngIf="(operation?.time_end ?? 0) > 0">{{ (operation?.time_end ?? 0) * 1000 | date: 'dd.MM.yyyy HH:mm' }}</span>
          </div>
          <div id="text" *ngIf="(operation?.text ?? '') != ''">{{ operation?.text }}</div>
          <section id="address">
            <h2>Adresse</h2>
            <div [innerHTML]="preparedAddress"></div>
          </section>
          <section id="answers">
            <h2>Einsatzkräfte ({{ (operation?.answers?.length ?? 0) }})</h2>
            <div id="answers-empty" *ngIf="(operation?.answers?.length ?? 0) <= 0">Noch keine Zusagen.</div>
            <ul>
              <li *ngFor="let a of operation?.answers">{{ a.first_name }} {{ a.last_name }}</li>
            </ul>
          </section>
          <section id="actions">
            <button *ngIf="showEndOperationBtn" (click)="onEndOperationBtnClick($event)" [disabled]="isEndingOperation">
              Einsatz beenden
            </button>
          </section>
        </div>
      </li>
      <li id="content-protocol" *ngIf="menuProtocolOpen">
        <div class="content-header" (click)="menuProtocolOpen = false">Protokoll (Beta)</div>
        <div class="content-body">
          <ul #protocolList>
            <li *ngFor="let pm of protocolMessages" [class.myself]="!pm.user">
              <div class="protocol-user-icon" *ngIf="pm.user"><img src="/assets/images/user-icon.webp"></div>
              <div class="protocol-message">
                <div class="protocol-message-user" *ngIf="pm.user">{{ pm.user.first_name }} {{ pm.user.last_name }}
                </div>
                <div class="protocol-message-text">{{ pm.message }}</div>
                <div class="protocol-message-time">{{ pm.time * 1000 | date: 'dd.MM.yyyy HH:mm' }}</div>
              </div>
            </li>
          </ul>
          <div id="protocol-input">
            <input type="text" placeholder="Nachricht schreiben" [(ngModel)]="newMessage"
                   (keydown)="onProtocolMessageKeyDown($event)" [disabled]="isSendingProtocolMessage">
            <mat-icon (click)="onSendProtocolMessageClick($event)">send</mat-icon>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <div id="map"></div>

  <!--<div id="menu">
    <div class="loading" *ngIf="isLoading || operation == null; else menuContent">Lade Daten</div>
    <ng-template #menuContent>
      <div id="category-indicator" *ngIf="operation?.category_color != null"
           [style.background-color]="operation?.category_color ?? '#ddd'"></div>
      <h1>{{ operation?.title }}</h1>
      <div id="time">{{ (operation?.time_start ?? 0) * 1000 | date: 'dd.MM.yyyy HH:mm' }} - <span
        *ngIf="(operation?.time_end ?? 0) > 0">{{ (operation?.time_end ?? 0) * 1000 | date: 'dd.MM.yyyy HH:mm' }}</span>
      </div>
      <div id="text" *ngIf="(operation?.text ?? '') != ''">{{ operation?.text }}</div>
      <section id="address">
        <h2>Adresse</h2>
        <div [innerHTML]="preparedAddress"></div>
      </section>
      <section id="answers">
        <h2>Einsatzkräfte ({{ (operation?.answers?.length ?? 0) }})</h2>
        <div id="answers-empty" *ngIf="(operation?.answers?.length ?? 0) <= 0">Noch keine Zusagen.</div>
        <ul>
          <li *ngFor="let a of operation?.answers">{{ a.first_name }} {{ a.last_name }}</li>
        </ul>
      </section>
      <section id="actions">
        <button *ngIf="showEndOperationBtn" (click)="onEndOperationBtnClick($event)" [disabled]="isEndingOperation">
          Einsatz beenden
        </button>
      </section>
    </ng-template>
  </div>-->

  <div id="content-right" class="content" *ngIf="false && menuResponsesOpen">
    <ul>
      <li id="content-responses" *ngIf="menuResponsesOpen">
        <div class="content-header" (click)="menuResponsesOpen = false">Rückmeldungen</div>
        <div class="content-body">
          Responses
        </div>
      </li>
    </ul>
  </div>

  <menu id="menu-right" *ngIf="false">
    <li (click)="menuResponsesOpen = !menuResponsesOpen" [class.active]="menuResponsesOpen">Rückmeldungen</li>
    <!--<li>Ressourcen</li>
    <li>Wasserkarte</li>-->
  </menu>
</main>
