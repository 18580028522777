<main>
  <div class="body-content-centered">
    <div class="loading" *ngIf="isLoading; else content">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <ng-template #content>
      <nav class="body-content-header-nav">
        <div class="body-content-header-nav-title">Monitore</div>
      </nav>

      <ul class="list">
        <li *ngFor="let monitor of monitors" (click)="onListItemClick(monitor.id, $event)">
          <div class="data">
            <div class="title">{{ monitor.label }}</div>
            <div class="status">{{ monitor.status_text }}</div>
          </div>
          <div class="indicator"><span [ngClass]="'status-' + monitor.status"></span></div>
        </li>
      </ul>

      <div *ngIf="monitors.length <= 0">
        Keine Monitore vorhanden.
      </div>
    </ng-template>
  </div>
</main>
