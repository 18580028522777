import {Component, OnDestroy, OnInit} from '@angular/core';
import {Api} from '../../../core/network/Api';
import {HttpClient} from '@angular/common/http';
import {OrganizationService} from '../../../core/services/organization.service';
import {Binding} from '../../../core/models/Binding';
import {Router} from '@angular/router';
import {ToolbarService} from "../../../core/services/toolbar.service";

@Component({
  selector: 'app-bindings',
  templateUrl: './bindings.component.html',
  styleUrls: ['./bindings.component.css']
})
export class BindingsComponent implements OnInit, OnDestroy {

  isLoading = false;
  // isLoadingActive = true;
  // isLoadingAvailable = true;

  bindings: Binding[] = [];

  constructor(private httpClient: HttpClient, private router: Router, private organizationService: OrganizationService,
              private toolbarService: ToolbarService) {
  }

  ngOnInit(): void {
    this.toolbarService.setHierarchy([{title: "Konfiguration", path: "/home/settings"}]);
    this.toolbarService.setPageTitle("Anbindungen");

    this.loadData();
  }

  ngOnDestroy() {
    this.toolbarService.resetHierarchy();
  }

  private loadData(): void {
    this.isLoading = true;
    Api.bindings(this.httpClient, {organization_id: '' + this.organizationService.selectedOrganizationId}, data => {
      // todo: handle if data.bindings is null
      if (data.bindings != null) {
        this.bindings = data.bindings.sort((a, b) => a.title > b.title ? 1 : -1);
      }
    }, (error: string) => {
      // todo: handle error
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  openBinding(key: string): void {
    if (key == '') return;
    this.router.navigateByUrl(`/home/settings/bindings/${key}`);
  }
}
