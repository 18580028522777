<main>
  <div class="body-content-centered">
    <h1>Ereignis erstellen</h1>

    <div id="form">
      <div class="loading" *ngIf="isLoading; else content">
        <mat-spinner color="accent" diameter="32"></mat-spinner>
      </div>

      <ng-template #content>
        <div id="content">
          <mat-form-field appearance="fill">
            <mat-label>Titel</mat-label>
            <input matInput [(ngModel)]="input.title" required>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Beschreibung</mat-label>
            <textarea matInput [(ngModel)]="input.text"></textarea>
          </mat-form-field>

          <div id="dates">
            <div>
              <span>Beginn</span>
              <mat-card class="demo-inline-calendar-card">
                <mat-calendar [(selected)]="dateStart" (selectedChange)="onCalendarSelectionChange($event)"
                              [minDate]="now"></mat-calendar>
              </mat-card>
              <mat-form-field appearance="outline">
                <mat-label>Beginnzeit</mat-label>
                <input matInput type="time" required [(ngModel)]="timeStart">
              </mat-form-field>
            </div>
            <div>
              <span>Ende</span>
              <mat-card class="demo-inline-calendar-card">
                <mat-calendar [(selected)]="dateEnd" (selectedChange)="onCalendarSelectionChange($event)"
                              [minDate]="dateStart"></mat-calendar>
              </mat-card>
              <mat-form-field appearance="outline">
                <mat-label>Endzeit</mat-label>
                <input matInput type="time" required [(ngModel)]="timeEnd">
              </mat-form-field>
            </div>
          </div>

          <h2>Empfänger</h2>
          <mat-form-field appearance="fill">
            <mat-label>Empfängerauswahl</mat-label>
            <mat-select [(ngModel)]="input.alarm.userSelectionTypeId">
              <mat-option [value]="1">Keine Empfänger</mat-option>
              <mat-option [value]="2">Sammelruf</mat-option>
            </mat-select>
          </mat-form-field>

          <h2>Erinnerungen</h2>
          <ul id="reminders">
            <li *ngFor="let tk of reminderScheduleTypeKeys">
              <mat-checkbox [(ngModel)]="tk.isChecked">{{ tk.name }}</mat-checkbox>
            </li>
          </ul>

          <h2>Adresse</h2>
          <mat-form-field appearance="fill">
            <mat-label>Name / Firma</mat-label>
            <input matInput [(ngModel)]="input.address.name">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Straße</mat-label>
            <input matInput [(ngModel)]="input.address.street">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Hausnummer</mat-label>
            <input matInput [(ngModel)]="input.address.streetNumber">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Stock</mat-label>
            <input matInput [(ngModel)]="input.address.floor">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Tür</mat-label>
            <input matInput [(ngModel)]="input.address.door">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>PLZ</mat-label>
            <input matInput [(ngModel)]="input.address.postcode">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Ort</mat-label>
            <input matInput [(ngModel)]="input.address.city">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Land</mat-label>
            <input matInput [(ngModel)]="input.address.country">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Freitext</mat-label>
            <input matInput [(ngModel)]="input.address.freeText">
          </mat-form-field>
          <br><br>

          <app-action-button [disabled]="isCreating || input.title == ''" iconKey="done" (click)="create()">
            Erstellen
          </app-action-button>
        </div>
      </ng-template>
    </div>
  </div>
</main>
