import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {OrganizationService} from '../../../../core/services/organization.service';
import {Api} from '../../../../core/network/Api';
import {License} from '../../../../core/models/License';
import {LicensePrice} from '../../../../core/models/LicensePrice';
import {MatDialog} from "@angular/material/dialog";
import {AlertComponent} from "../../../../dialogs/alert/alert.component";
import {ToolbarService} from "../../../../core/services/toolbar.service";
import {Plan} from "../../../../core/models/Plan";
import {LicenseChangePreview} from "../../../../core/models/LicenseChangePreview";
import {CheckoutSuccessComponent} from "../../../../dialogs/checkout-success/checkout-success.component";
import {LicenseType} from "../../../../core/constants/LicenseType";

@Component({
  selector: 'app-license-cancel',
  templateUrl: './license-cancel.component.html',
  styleUrls: ['./license-cancel.component.css']
})
export class LicenseCancelComponent implements OnInit, OnDestroy {
  isLoading = false;
  isWorking = false;

  isLoadingPlans = false;

  price: LicensePrice | null = null;

  license: License | null = null;

  plans: Plan[] = [];
  selectedPlanId = '';

  isLoadingInvoicePreview = false;
  invoicePreview: LicenseChangePreview | null = null;

  isAcceptingTerms = false;

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService, private router: Router,
              public dialog: MatDialog, private toolbarService: ToolbarService) {
  }

  ngOnInit(): void {
    this.toolbarService.setHierarchy([
      {title: "Konfiguration", path: "/home/settings"},
      {title: "Abrechnung", path: "/home/settings/billing"}
    ]);
    this.toolbarService.setPageTitle("Plan ändern");

    this.loadLicense();
  }

  ngOnDestroy() {
    this.toolbarService.resetHierarchy();
  }

  onPlanSelected(planId: string): void {
    this.selectedPlanId = planId;
    this.invoicePreview = null;

    if (this.selectedPlanId != this.license?.billing_period) {
      this.loadChangePreview();
    }
  }

  loadLicense() {
    this.isLoading = true;

    Api.license(this.httpClient, {organization_id: this.organizationService.selectedOrganizationId}, response => {
      this.license = response.license;

      if (this.license.type != LicenseType.PAID) {
        this.showAlert("Aktion nicht unterstützt", "Dein Plan unterstützt diese Aktion nicht.", () => {
          this.router.navigateByUrl("/home/settings/billing");
        })
        return;
      }

      if (this.license.is_cancelled) {
        this.showAlert("Plan bereits gekündigt", "Dieser Plan wurde bereits gekündigt.", () => {
          this.router.navigateByUrl("/home/settings/billing");
        })
        return;
      }
      this.isLoading = false;

      this.selectedPlanId = this.license.billing_period;

      this.loadPlans();
    }, error => {
      this.showAlert("Fehler beim Laden", "Bitte versuche es später nochmal.", () => {
        this.router.navigateByUrl("/home/settings/billing");
      });
    });
  }

  loadChangePreview(): void {
    this.isLoadingInvoicePreview = true;

    Api.licenseChangePreview(this.httpClient, {organization_id: `${this.organizationService.selectedOrganizationId}`}, (preview) => {
      this.invoicePreview = preview;
      this.isLoadingInvoicePreview = false;
    }, (error) => {
      this.isLoadingInvoicePreview = false;
      this.showAlert('Fehler beim Laden', 'Bitte versuche es später nochmal.', () => {
        this.router.navigateByUrl('/home/settings/billing');
      })
    }, () => {
    }, this.selectedPlanId)
  }

  private loadPlans() {
    this.isLoadingPlans = true;

    Api.planAvailable(this.httpClient, {organization_id: this.organizationService.selectedOrganizationId}, data => {
      this.plans = data.plans;
    }, error => {
      this.showAlert("Fehler beim Laden", "Bitte versuche es später nochmal.", () => {
        this.router.navigateByUrl("/home/settings/billing");
      });
    }, () => {
      this.isLoadingPlans = false;
    });
  }

  cancelLicense() {
    this.isWorking = true;

    Api.licenseCancel(this.httpClient, {organization_id: `${this.organizationService.selectedOrganizationId}`}, response => {
      this.showAlert("Plan gekündigt", "Dein Plan wird mit Ende des Abrechnungszeitraumes gekündigt.", () => {
        this.router.navigateByUrl('/home/settings/billing');
      })
    }, error => {
      switch (error) {
        case 'not-allowed':
          this.showAlert("Fehler", "Das Kündigen dieses Plans ist nicht möglich. Bitte kontaktiere unseren Support für weitere Informationen.");
          break;
        default:
          this.showAlert("Interner Fehler", "Interner Fehler bei der Verarbeitung. Bitte versuche es später nochmal.");
      }
      this.isWorking = false;
      this.isLoading = false;
    });
  }

  changePlan(): void {
    this.isWorking = true;

    Api.licenseSchedule(this.httpClient, {
      organization_id: `${this.organizationService.selectedOrganizationId}`,
      billing_period: this.selectedPlanId
    }, response => {
      this.dialog
        .open(CheckoutSuccessComponent, {disableClose: true})
        .afterClosed().subscribe(result => {
        this.router.navigateByUrl('/home/settings/billing');
      });
    }, error => {
      this.dialog.open(AlertComponent, {
        data: {
          title: "Fehler beim Ändern des Plans",
          text: "Wegen eines Fehlers konnte deine Aktion leider nicht erfolgreich abgeschlossen werden. Bitte versuche es später nochmal oder kontaktiere uns über support@smartpager.at."
        },
        disableClose: true
      }).afterClosed().subscribe(() => {
        this.isWorking = false;
      });
    }, () => {
      this.isWorking = false;
      this.isLoading = false;
    })

  }

  private showAlert(title: string, text: string, afterClosed: (() => any) | null = null) {
    this.dialog.open(AlertComponent, {
      data: {
        title: title,
        text: text
      },
      disableClose: true
    }).afterClosed().subscribe(() => {
      if (afterClosed) afterClosed();
    });
  }
}
