<main>
  <div class="body-content-centered">
    <nav class="body-content-header-nav">
      <div class="body-content-header-nav-title">MapTrip</div>
    </nav>

    <div>
      <p>Bei Einsatzerstellung kann die Navigation zum Einsatzort direkt auf deinen MapTrip-Geräten gestartet
        werden.</p>
      <p><a href="https://www.maptrip.de" target="_blank">Website von MapTrip</a> (infoware GmbH)</p>
    </div>

    <mat-expansion-panel id="privacy-info">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>shield</mat-icon>
          Datenschutz
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>Es werden an MapTrip ausschließlich die Koordinaten sowie der Titel des Einsatzes übermittelt.</p>
    </mat-expansion-panel>

    <div class="loading" *ngIf="isLoading; else content">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <ng-template #content>
      <div id="content">
        <div id="login" *ngIf="!info?.is_active">
          <div>Erstelle einen neuen API Key für SmartPager im <a href="https://manager.maptrip.de" target="_blank">MapTrip
            Manager</a> und füge ihn hier ein.
          </div>
          <br>

          <mat-form-field appearance="fill" color="accent">
            <mat-label>API Key</mat-label>
            <input matInput type="text" placeholder="" [(ngModel)]="apiKey" [disabled]="isProcessing" required>
          </mat-form-field>
        </div>

        <div id="status" *ngIf="info?.is_active">
          <span></span><b>Aktiv</b>
        </div>

        <div id="actions" *ngIf="info != null">
          <app-action-button *ngIf="!info?.is_active" (click)="enableBinding()" [disabled]="isProcessing">
            Aktivieren
          </app-action-button>
          <app-action-button *ngIf="info?.is_active" (click)="disableBinding()" [disabled]="isProcessing">
            Anbindung deaktivieren
          </app-action-button>
        </div>
      </div>
    </ng-template>
  </div>
</main>
