import {Component, OnDestroy, OnInit} from '@angular/core';
import {License} from "../../../../core/models/License";
import {PlanUsage} from "../../../../core/network/models/PlanUsage";
import {Api} from "../../../../core/network/Api";
import {HttpClient} from "@angular/common/http";
import {OrganizationService} from "../../../../core/services/organization.service";
import {AlertComponent} from "../../../../dialogs/alert/alert.component";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {ToolbarService} from "../../../../core/services/toolbar.service";
import {LicenseType} from "../../../../core/constants/LicenseType";

@Component({
  selector: 'app-license-usage',
  templateUrl: './license-usage.component.html',
  styleUrls: ['./license-usage.component.scss']
})
export class LicenseUsageComponent implements OnInit, OnDestroy {

  isLoadingPlan = false;
  isLoadingUsage = false;

  plan: License | null = null;
  planUsage: PlanUsage[] = [];

  usagePeriod = '';

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService, private router: Router,
              private dialog: MatDialog, private toolbarService: ToolbarService) {
  }

  ngOnInit(): void {
    this.toolbarService.setHierarchy([
      {title: "Konfiguration", path: "/home/settings"},
      {title: "Abrechnung", path: "/home/settings/billing"}
    ]);
    this.toolbarService.setPageTitle("Nutzung");

    this.loadPlan();
    this.loadPlanUsage();
  }

  ngOnDestroy(): void {
    this.toolbarService.resetHierarchy();
  }

  private loadPlan() {
    this.isLoadingPlan = true;

    Api.license(this.httpClient, {organization_id: this.organizationService.selectedOrganizationId}, response => {
      //console.log(response);
      this.plan = response.license;

      if (this.plan.type == "beta" || this.plan.type == "trial") {
        this.usagePeriod = "Testzeitraum";
      } else if (this.plan.type == LicenseType.PAID) {
        this.usagePeriod = "Abrechnungszeitraum";
      } else {
        this.usagePeriod = "Monat";
      }

      this.isLoadingPlan = false;
    }, error => {
      this.showAlert("Fehler beim Laden", "Bitte versuchen Sie es später nochmal.", () => {
        this.router.navigateByUrl("/home/settings/billing");
      });
    });
  }

  private loadPlanUsage() {
    this.isLoadingUsage = true;

    Api.planUsage(this.httpClient, {organization_id: this.organizationService.selectedOrganizationId}, response => {
      //console.log(response);
      this.planUsage = response.data.plan_usages;

      this.isLoadingUsage = false;
    }, error => {
      this.showAlert("Fehler beim Laden", "Bitte versuchen Sie es später nochmal.", () => {
        this.router.navigateByUrl("/home/settings/billing");
      });
    });
  }

  private showAlert(title: string, text: string, afterClosed: (() => any) | null = null) {
    this.dialog.open(AlertComponent, {
      data: {
        title: title,
        text: text
      },
      disableClose: true
    }).afterClosed().subscribe(() => {
      if (afterClosed) afterClosed();
    });
  }

  navigateTo(route: string) {
    this.router.navigateByUrl(route);
  }
}
