import {Component, OnDestroy, OnInit} from '@angular/core';
import {ToolbarService} from "../../../../core/services/toolbar.service";
import {Monitor} from "../../../../core/network/models/Monitor";
import {OrganizationService} from "../../../../core/services/organization.service";
import {ApiService} from "../../../../core/services/api.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-monitor-details',
  templateUrl: './monitor-details.component.html',
  styleUrls: ['./monitor-details.component.scss']
})
export class MonitorDetailsComponent implements OnInit, OnDestroy {

  isLoading = false;

  id: string | null = null;
  monitor: Monitor | null = null;

  constructor(private toolbarService: ToolbarService, private organizationService: OrganizationService,
              private apiService: ApiService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.id = paramMap.get('id');
      if ((this.id ?? '') != '') {
        this.loadData();
      }
    });

    this.toolbarService.setHierarchy([
      {title: "Konfiguration", path: "/home/settings"},
      {title: "Monitore", path: "/home/settings/monitors"}
    ]);
    this.toolbarService.setPageTitle("");
  }

  ngOnDestroy(): void {
    this.toolbarService.resetHierarchy();
  }

  onOpenMonitorClick(event: any): void {
    if (this.monitor?.status == 'connected') {
      alert('Der Monitor ist bereits aktiv. Schließe das Fenster, in dem')
    }
  }

  onDeleteClick(event: any): void {
    // todo
  }

  private loadData(): void {
    if (this.id == null) return;

    this.isLoading = true;

    const organizationId = this.organizationService.getSelectedOrganization()?.id ?? 0;
    this.apiService.monitorDetails({
      organization_id: organizationId.toString(),
      monitor_id: this.id ?? ''
    }, response => {
      this.monitor = response.item;
      this.toolbarService.setPageTitle(this.monitor.label);
      this.isLoading = false;
    }, error => {
      // todo: show error
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }
}
