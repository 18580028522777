import {Component, OnDestroy, OnInit} from '@angular/core';
import {Api} from '../../../../core/network/Api';
import {Router} from '@angular/router';
import {OrganizationService} from '../../../../core/services/organization.service';
import {HttpClient} from '@angular/common/http';
import {OrganizationType} from '../../../../core/models/OrganizationType';
import {Country} from '../../../../core/models/Country';
import {AlertComponent} from "../../../../dialogs/alert/alert.component";
import {MatDialog} from "@angular/material/dialog";
import {ToolbarService} from "../../../../core/services/toolbar.service";

@Component({
  selector: 'app-organization-update',
  templateUrl: './organization-update.component.html',
  styleUrls: ['./organization-update.component.css']
})
export class OrganizationUpdateComponent implements OnInit, OnDestroy {
  isLoadingInformation = false;
  isLoadingOrganizationTypes = false;
  isLoadingCountries = false;

  isSaving = false;

  organizationTypes: OrganizationType[] = [];
  countries: Country[] = [];

  organizationType = '';
  organizationName = '';
  organizationEmail = '';
  organizationPhone = '';
  addressName = '';
  addressStreet = '';
  addressPostcode = '';
  addressCity = '';
  addressCountryId = 0;

  hasError = false;

  constructor(private router: Router, private organizationService: OrganizationService, private httpClient: HttpClient,
              public dialog: MatDialog, private toolbarService: ToolbarService) {
  }

  ngOnInit(): void {
    this.toolbarService.setHierarchy([{title: "Konfiguration", path: "/home/settings"}]);
    this.toolbarService.setPageTitle("Daten aktualisieren");

    this.loadData();
  }

  ngOnDestroy() {
    this.toolbarService.resetHierarchy();
  }

  private loadData() {
    this.loadOrganizationInformation();
    this.loadOrganizationTypes();
    this.loadCountries();
  }

  private loadOrganizationInformation() {
    this.isLoadingInformation = true;

    Api.organizationInformation(this.httpClient, {organization_id: `${this.organizationService.selectedOrganizationId}`}, response => {
      this.organizationType = response.organization.type;
      this.organizationName = response.organization.name;
      this.organizationEmail = response.organization.email;
      this.organizationPhone = response.organization.phone;
      this.addressName = response.address.name;
      this.addressStreet = response.address.street;
      this.addressPostcode = response.address.postcode;
      this.addressCity = response.address.city;
      this.addressCountryId = response.address.country_id;
    }, (error: string) => {
      this.hasError = true;
    }, () => {
      this.isLoadingInformation = false;
    });
  }

  private loadOrganizationTypes() {
    this.isLoadingOrganizationTypes = true;

    Api.organizationTypes(this.httpClient, response => {
      this.organizationTypes = response.organization_types;
    }, (error: string) => {
      this.hasError = true;
    }, () => {
      this.isLoadingOrganizationTypes = false;
    });
  }

  private loadCountries() {
    this.isLoadingCountries = true;

    Api.countries(this.httpClient, response => {
      this.countries = response.countries;
    }, (error: string) => {
      this.hasError = true;
    }, () => {
      this.isLoadingCountries = false;
    });
  }

  save(): void {
    this.isSaving = true;

    Api.organizationInformationUpdate(this.httpClient, {
      organization_id: '' + this.organizationService.selectedOrganizationId,
      organization_name: this.organizationName,
      organization_email: this.organizationEmail,
      organization_phone: this.organizationPhone,
      address_name: this.addressName,
      address_street: this.addressStreet,
      address_postcode: this.addressPostcode,
      address_city: this.addressCity,
      address_country_id: this.addressCountryId
    }, () => {
      let organization = this.organizationService.getSelectedOrganization();
      organization!.name = this.organizationName;
      this.router.navigateByUrl('/home/settings');
    }, (error: string) => {
      console.log(error);
      switch (error) {
        case 'email-format':
          this.showAlert("Ungültiges E-Mail-Format", "Bitte überprüfe deine Eingaben und versuche es nochmal.");
          break;
        case 'email-duplicate':
          this.showAlert("E-Mail-Adresse in Verwendung", "Diese E-Mail-Adresse ist bereits in Verwendung. Bei Fragen schreibe uns bitte an support@smartpager.at");
          break;
        default:
          this.showAlert("Unbekannter Fehler", "Unbekannter Fehler beim Speichern! Bitte versuche es später nochmal.");
      }
      this.isSaving = false;
    }, () => {
    });
  }

  private showAlert(title: string, text: string, afterClosed: (() => any) | null = null) {
    this.dialog.open(AlertComponent, {
      data: {
        title: title,
        text: text
      },
      disableClose: true
    }).afterClosed().subscribe(() => {
      if (afterClosed) afterClosed();
    });
  }
}
