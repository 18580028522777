<main>
  <h1>Plan ändern</h1>

  <div id="content">
    <div class="loading" *ngIf="isLoading || isLoadingPlans; else content">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <ng-template #content>
      <section id="section-plans">
        <h2>PLÄNE</h2>

        <div *ngIf="license?.schedule?.time_scheduled != null; else changePlanTemplate">Dein Plan wird
          am {{ license!.schedule!.time_scheduled * 1000 | date: 'dd. MMMM yyyy' }} gemäß deiner Bestellung
          geändert.<br>Bei Fragen schreibe uns
          gerne an <a href="mailto:support@smartpager.at">support@smartpager.at</a></div>
        <ng-template #changePlanTemplate>
          <ul id="plan-selector">
            <li *ngFor="let p of plans">
              <app-plan-item [plan]="p" [isSelected]="selectedPlanId == p.id"
                             (click)="onPlanSelected(p.id)"></app-plan-item>
            </li>
          </ul>
          <div id="plan-selector-info">Preise exkl. USt.</div>

          <ng-container *ngIf="selectedPlanId != license?.billing_period">
            <h2>Zahlungsmethode</h2>
            <payment-method></payment-method>

            <div id="invoice-preview-title">
              Rechnungsvorschau:
            </div>
            <div id="invoice-preview">
              <div id="loading-invoice-preview"
                   *ngIf="isLoadingInvoicePreview || invoicePreview == null; else invoicePreviewContent">
                <mat-spinner diameter="32" color="accent"></mat-spinner>
                <div>Vorschau wird geladen ...</div>
              </div>

              <ng-template #invoicePreviewContent>
                <div id="lines" *ngIf="invoicePreview?.lines">
                  <ul>
                    <li *ngFor="let l of invoicePreview?.lines">
                      <div>{{ l.text }}</div>
                      <div>{{ l.amount }}</div>
                    </li>
                  </ul>
                </div>

                <div id="subtotal">
                  <div>Zwischensumme</div>
                  <div>{{ invoicePreview?.subtotal }}</div>
                </div>

                <div class="discount" *ngFor="let d of invoicePreview?.discounts">
                  <div>{{ d.name }}</div>
                  <div>{{ d.amount }}</div>
                </div>

                <div id="taxes-info">
                  <div *ngIf="invoicePreview?.is_reverse_charge; else taxesInfo">
                    Steuer zahlbar auf Reverse-Charge-Basis
                  </div>

                  <ng-template #taxesInfo>
                    <div>{{ invoicePreview?.tax_rate_percentage }} {{ invoicePreview?.tax_rate_name }}</div>
                    <div>{{ invoicePreview?.tax_rate_amount }}</div>
                  </ng-template>
                </div>

                <div id="total">
                  <div>{{ invoicePreview?.is_credit_advice ? "Gutschrift" : "Gesamt" }}</div>
                  <div>{{ invoicePreview?.total }}</div>
                </div>
              </ng-template>
            </div>

            <div id="invoice-preview-info">Dein aktueller Plan wird mit Ende des Abrechnungszeitraumes auf den
              ausgewählten Plan geändert. Die tatsächlichen Kosten werden
              <b>{{ selectedPlanId == 'month' ? 'monatlich' : 'jährlich' }}</b> aufgrund der maximalen
              Mitgliederanzahl im Nachhinein berechnet. Die Kündigung ist zum Ende jedes Abrechnungszeitraumes möglich.
            </div>

            <label id="legal"><input type="checkbox" [(ngModel)]="isAcceptingTerms" [disabled]="isWorking"> Ich stimme
              den
              <a href="https://www.smartpager.at/terms" target="_blank">allgemeinen Geschäftsbedingungen</a> von
              Elias Lexl und den Bedingungen der automatischen Verlängerung zu</label>

            <div>
              <button class="btn-action btn-primary"
                      [disabled]="!isAcceptingTerms || isWorking || isLoadingInvoicePreview"
                      (click)="changePlan()">Kostenpflichtig bestellen
              </button>
            </div>
          </ng-container>
        </ng-template>
      </section>

      <section id="section-cancel">
        <h2>PLAN KÜNDIGEN</h2>
        <div *ngIf="license?.schedule?.time_scheduled != null; else planCancelInfo">Aufgrund einer bevorstehenden
          Planänderung ist die Kündigung derzeit nicht verfügbar.<br>Um deinen Plan zu kündigen schreibe uns
          bitte an <a href="mailto:support@smartpager.at">support@smartpager.at</a></div>
        <ng-template #planCancelInfo>
          <div id="plan-cancel-info" *ngIf="license?.time_end != null">
            <p>Dein Plan wird gekündigt, ist jedoch bis zum Ende des Abrechnungszeitraumes
              am <b>{{ license!.time_end * 1000 | date: 'dd. MMMM yyyy' }}</b> weiterhin verfügbar.</p>
            <p>Wenn du deine Meinung vorher änderst, kannst du das Abonnement verlängern.</p>
          </div>
          <button class="btn-action btn-secondary btn-red" [disabled]="isWorking" (click)="cancelLicense()">
            Plan kündigen
          </button>
        </ng-template>
      </section>


      <button class="btn-action btn-secondary" [disabled]="isWorking" [routerLink]="['/home/settings/billing']">
        Zurück
      </button>
    </ng-template>
  </div>
</main>
