import {Component, OnDestroy, OnInit} from '@angular/core';
import {Operation} from "../../../core/models/Operation";
import {HttpClient} from "@angular/common/http";
import {ApiService} from "../../../core/services/api.service";
import {OrganizationService} from "../../../core/services/organization.service";
import {OperationListRequest} from "../../../core/network/request/OperationListRequest";
import {OperationListResponse} from "../../../core/network/response/OperationListResponse";
import {ActivatedRoute, Router} from "@angular/router";
import {ToolbarService} from "../../../core/services/toolbar.service";

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss']
})
export class OperationsComponent implements OnInit {

  isLoading = false;

  isOperationManager = false;

  operations: Operation[] = [];

  constructor(private httpClient: HttpClient, private apiService: ApiService,
              private organizationService: OrganizationService, private router: Router, private route: ActivatedRoute,
              private toolbarService: ToolbarService) {
    this.isOperationManager = organizationService.getSelectedOrganization()?.is_operation_manager ?? false;
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.isLoading = true;

    const request = {organization_id: this.organizationService.getSelectedOrganization()?.id ?? 0} as OperationListRequest;

    this.apiService.operationList(request, (response: OperationListResponse) => {
      this.operations = response.items.sort((a, b) => {
        return b.time_start - a.time_start
      });
    }, (error: string) => {
      // todo
    }, () => {
      this.isLoading = false;
    })
  }

  open(id: string): void {
    this.router.navigate([id], {relativeTo: this.route});
  }
}
