import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {OrganizationService} from '../../../../core/services/organization.service';
import {Router} from '@angular/router';
import {Api} from '../../../../core/network/Api';
import {License} from '../../../../core/models/License';
import {LicensePrice} from '../../../../core/models/LicensePrice';
import {AlertComponent} from "../../../../dialogs/alert/alert.component";
import {MatDialog} from "@angular/material/dialog";
import {ToolbarService} from "../../../../core/services/toolbar.service";
import {LicenseType} from "../../../../core/constants/LicenseType";

@Component({
  selector: 'app-license-renew',
  templateUrl: './license-renew.component.html',
  styleUrls: ['./license-renew.component.css']
})
export class LicenseRenewComponent implements OnInit, OnDestroy {
  isLoading = false;
  isWorking = false;
  isLoadingPricing = false;

  price: LicensePrice | null = null;
  license: License | null = null;

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService, private router: Router,
              public dialog: MatDialog, private toolbarService: ToolbarService) {
  }

  ngOnInit(): void {
    this.toolbarService.setHierarchy([
      {title: "Konfiguration", path: "/home/settings"},
      {title: "Abrechnung", path: "/home/settings/billing"}
    ]);
    this.toolbarService.setPageTitle("Plan verlängern");

    this.loadLicense();
  }

  ngOnDestroy(): void {
    this.toolbarService.resetHierarchy();
  }

  private loadLicense(): void {
    this.isLoading = true;

    Api.license(this.httpClient, {organization_id: this.organizationService.selectedOrganizationId}, response => {
      this.license = response.license;

      if (this.license.type != LicenseType.PAID) {
        this.showAlert("Aktion nicht unterstützt", "Dein Plan unterstützt diese Aktion nicht.", () => {
          this.router.navigateByUrl("/home/settings/billing");
        })
        return;
      }

      if (!this.license.is_cancelled) {
        this.showAlert("Plan aktiv", "Dein Plan wird bereits automatisch verlängert.", () => {
          this.router.navigateByUrl("/home/settings/billing");
        })
        return;
      }

      this.loadLicensePricing();
      this.isLoading = false;
    }, error => {
      this.showAlert("Fehler beim Laden", "Bitte versuche es später nochmal.", () => {
        this.router.navigateByUrl("/home/settings/billing");
      });
    });
  }

  private loadLicensePricing(): void {
    this.isLoadingPricing = true;

    Api.licensePricing(this.httpClient, {organization_id: this.organizationService.selectedOrganizationId}, response => {
      this.price = response;
    }, error => {
      this.showAlert("Fehler beim Laden", "Bitte versuche es später nochmal.", () => {
        this.router.navigateByUrl("/home/settings/billing");
      });
    }, () => {
      this.isLoadingPricing = false;
    }, this.license?.billing_period);
  }

  onSubmit(): void {
    this.isWorking = true;

    Api.licenseRenew(this.httpClient, {organization_id: `${this.organizationService.selectedOrganizationId}`}, () => {
      this.showAlert("Plan verlängert", "Dein Plan wird nicht mehr gekündigt und automatisch verlängert.", () => {
        this.router.navigateByUrl("/home/settings/billing");
      });
    }, error => {
      switch (error) {
        case 'not-allowed':
          this.showAlert("Fehler", "Das Verlängern dieses Plans ist nicht möglich. Bitte kontaktiere unseren Support für weitere Informationen.");
          break;
        default:
          this.showAlert("Interner Fehler", "Interner Fehler bei der Verarbeitung. Bitte versuche es später nochmal.");
      }
      this.isWorking = false;
      this.isLoading = false;
    }, () => {
    });
  }

  private showAlert(title: string, text: string, afterClosed: (() => any) | null = null) {
    this.dialog.open(AlertComponent, {
      data: {
        title: title,
        text: text
      },
      disableClose: true
    }).afterClosed().subscribe(() => {
      if (afterClosed) afterClosed();
    });
  }
}
