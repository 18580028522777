<main>
  <div class="body-content-centered">
    <div class="loading" *ngIf="isLoading; else content">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <ng-template #content>
      <h2>ROADMAP</h2>

      <ul class="list">
        <li *ngFor="let entry of entries" [style.background-color]="entry.status.color">
          <div class="type">
            <div class="type-title" [style.background-color]="entry.type.color">{{ entry.type.title.toUpperCase() }}
            </div>
            <div class="issue-id" title="SmartPager ID" *ngIf="entry.issue_id != null">{{ entry.issue_id }}</div>
          </div>
          <div class="details">
            <div class="title"><span>{{ entry.title }}</span></div>
            <div class="description">{{ entry.description }}</div>
            <div class="time_planned" *ngIf="entry.time_planned != null">
              Geplant für {{ entry.time_planned * 1000 | date: 'MM.yyyy' }}
            </div>
          </div>
          <div class="status" title="Status" *ngIf="entry.status.title != ''">{{ entry.status.title }}</div>
        </li>
      </ul>

      <div *ngIf="entries.length <= 0">
        Keine Neuigkeiten vorhanden.
      </div>
    </ng-template>
  </div>
</main>
