<main>
  <div class="body-content-centered">
    <div id="loading" *ngIf="isLoadingSettings; else content">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <ng-template #content>
      <nav class="body-content-header-nav">
        <div class="body-content-header-nav-title">Datenschutz</div>
      </nav>

      <div id="content">
        <h2>Einsätze</h2>
        <mat-form-field appearance="fill">
          <mat-label>Automatisch löschen</mat-label>
          <mat-select [(ngModel)]="operationAutoDelete">
            <mat-option value="never">nie</mat-option>
            <mat-option value="operation-end">mit Einsatzende</mat-option>
            <mat-option value="24h">nach 24 Stunden</mat-option>
            <mat-option value="30d">nach 30 Tagen</mat-option>
            <mat-option value="90d">nach 90 Tagen</mat-option>
            <mat-option value="180d">nach 180 Tagen</mat-option>
            <mat-option value="1y">nach einem Jahr</mat-option>
          </mat-select>
          <mat-hint>Achtung: Diese Einstellung betrifft auch bestehende Einsätze!</mat-hint>
        </mat-form-field>

        <div id="actions">
          <app-action-button iconKey="done" [disabled]="isSaving" (click)="save()">Speichern</app-action-button>
        </div>

        <br>
        <br>
        <ng-container *ngIf="isCompany">
          <h2>Auftragsverarbeitung</h2>
          <div class="info">Für die Nutzung von SmartPager kann der Abschluss eines Auftragsverarbeitungsvertrages gemäß
            Art. 28 DSGVO erforderlich sein.
          </div>
          <button class="btn-action btn-secondary" (click)="sendPrivacyEmail()">
            AV-Vertrag abschließen
          </button>
        </ng-container>
      </div>
    </ng-template>
  </div>
</main>
