<main>
  <div id="dialog-title">Wähle eine Organisation</div>
  <ul>
    <li *ngFor="let o of getOrganizations()"
        [class.active]="o.id == selectedOrganizationId"
        (click)="onOrganizationClick($event, o.id)">
      <div class="organization-title">{{ o.name }}</div>
      <div class="organization-details">
        Kundennummer: {{ o.id.toString().padStart(6, '0') }}, {{ mapOrganizationTypeToName(o.organization_type_id) }}
      </div>
    </li>
  </ul>
</main>
