import {AfterViewInit, Component, ElementRef, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ApiService} from "../../../../core/services/api.service";
import {OrganizationService} from "../../../../core/services/organization.service";
import {Operation} from "../../../../core/models/Operation";
import {ActivatedRoute, Router} from "@angular/router";
import {OperationDetailRequest} from "../../../../core/network/request/OperationDetailRequest";
import {OperationDetailResponse} from "../../../../core/network/response/OperationDetailResponse";
import * as L from "leaflet";
import {LayerGroup} from "leaflet";
import {ToolbarService} from "../../../../core/services/toolbar.service";
import {OperationProtocolMessage} from "../../../../core/models/OperationProtocolMessage";
import {MatSnackBar} from "@angular/material/snack-bar";

/*import "leaflet/dist/leaflet.css";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";*/


@Component({
  selector: 'app-operation-detail',
  templateUrl: './operation-detail.component.html',
  styleUrls: ['./operation-detail.component.scss']
})
export class OperationDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  isLoading = false;

  menuOperationOpen = true;
  menuProtocolOpen = true;
  menuResponsesOpen = true;

  id: string | null = null;
  operation: Operation | null = null;
  preparedAddress: string = '';

  private map: L.Map | null = null;

  private layerGroupOperationAddress: LayerGroup | null = null;
  private layerGroupWaterSources: LayerGroup | null = null;

  @ViewChild('protocolList') private protocolList: ElementRef | null = null;
  protocolMessages: OperationProtocolMessage[] = [];

  private protocolMessageSyncInterval: number | null = null;

  isSendingProtocolMessage: boolean = false;
  newMessage: string = '';

  showEndOperationBtn = false;
  isEndingOperation = false;

  constructor(private apiService: ApiService, private organizationService: OrganizationService,
              private route: ActivatedRoute, private router: Router, private toolbarService: ToolbarService,
              private _snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.id = paramMap.get('id');
      if ((this.id ?? '') != '') {
        this.loadData(this.id!!);
      }
    });

    this.toolbarService.setActions([{icon: "close", path: "/home/operations", info: "Ansicht schließen"}]);

    /*L.PM.setOptIn(true);
    this.map?.pm.addControls({
      position: 'topleft',
      drawCircleMarker: false,
      rotateMode: false,
    });*/
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  ngOnDestroy(): void {
    this.toolbarService.resetActions();

    if (this.protocolMessageSyncInterval) {
      clearInterval(this.protocolMessageSyncInterval);
    }
  }

  onEndOperationBtnClick(event: any): void {
    if (confirm("Möchtest du diesen Einsatz wirklich beenden?")) {

      this.isEndingOperation = true;

      let organizationId = `${this.organizationService.getSelectedOrganization()!.id}`;
      let operationId = `${this.operation!.id}`

      this.apiService.operationEnd(organizationId, operationId, response => {
        this.router.navigateByUrl('/home/operations');
      }, error => {
        alert('Fehler beim Beenden des Einsatzes!');
        this.isEndingOperation = false;
      }, () => {
        this.isEndingOperation = false;
      })
    }
  }

  private initMap(): void {
    // const center = this.mapService.getCenter();
    this.map = L.map('map', {
      center: [48, 13],
      zoom: 15, // this.mapService.getZoom(),
      attributionControl: true,
      zoomControl: false,
      dragging: true
    });

    L.control.zoom({
      position: 'topright'
    }).addTo(this.map);

    let tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenStreetMap',
      detectRetina: false
    });
    tiles.addTo(this.map!);

    this.layerGroupOperationAddress = L.layerGroup().addTo(this.map);
    this.layerGroupWaterSources = L.layerGroup().addTo(this.map);

    /*this.mapGroupSearchMarker = L.layerGroup().addTo(this.map);
    this.mapGroupOperationAreas = L.layerGroup().addTo(this.map);
    this.mapGroupAmbulanceDepartments = L.layerGroup().addTo(this.map);
    this.mapGroupPositions = L.layerGroup().addTo(this.map);
    this.mapGroupMapObjects = L.layerGroup().addTo(this.map);
    this.mapGroupSearchMarker.setZIndex(100);
    this.mapGroupResources = L.layerGroup().addTo(this.map);
    this.mapGroupResources.setZIndex(10);
    this.setMapType(this.mapType);
    this.map.on('moveend', (x: any) => {
      this.onCenterChanged();
    });
    this.map.on('zoomend', (x: any) => {
      this.onZoomChanged();
    })
    this.setCenter(center.lat, center.lng);
    this.setZoomLevel(this.mapService.getZoom());
    this.onCenterChanged();*/
  }

  private loadData(id: string): void {
    this.isLoading = true;

    const request = {
      organization_id: this.organizationService.getSelectedOrganization()?.id ?? 0,
      operation_id: id
    } as OperationDetailRequest;

    this.apiService.operationDetail(request, (response: OperationDetailResponse) => {
      this.operation = response.item;
      this.preparedAddress = this.toLocationString('<br>') ?? '';

      this.map?.setView([this.operation?.address?.latitude ?? 0, this.operation?.address?.longitude ?? 0])
      this.setOperationAddressMarker()
      this.loadWaterSources();

      this.loadProtocolMessages(true, true);
      this.startProtocolMessageSyncInterval();

      let org = this.organizationService.getSelectedOrganization();
      this.showEndOperationBtn = (this.operation?.time_end ?? 0) == 0 && (org?.is_operation_manager ?? false);
    }, (error: string) => {
      if (error == "not-found") {
        this.router.navigateByUrl('/');
      }
      // todo: handle other errors
    }, () => {
      this.isLoading = false;
    })
  }

  private setOperationAddressMarker() {
    if (this.operation?.address == null) return;

    let addressMarker = L.marker({
      lat: this.operation.address.latitude ?? 0,
      lng: this.operation.address.longitude ?? 0
    }, {
      draggable: false,
      /*icon: L.icon({
        iconUrl: 'assets/ic_my_location.png',
        iconSize: [40, 40]
      }),*/
      icon: L.icon({
        iconUrl: 'assets/destination-marker.png',
        iconSize: [40, 64],
        iconAnchor: [20, 64]
      }),
      /*icon: new L.DivIcon({
        iconSize: [22, 22],
        iconAnchor: [11, 11],
        className: 'map-object-marker',
        html: `<img src="${url}">`
      }),*/
      zIndexOffset: 100
    });
    addressMarker.bindPopup(L.popup({closeButton: false}).setLatLng({
      lat: this.operation.address.latitude ?? 0,
      lng: this.operation.address.longitude ?? 0
    }).setContent(`<b>Einsatzort</b><br>${this.toLocationString(',')}`));
    addressMarker.addTo(this.layerGroupOperationAddress!);
  }

  private toLocationString(separator: string = "\n", justStreetAddress: boolean = false): string {
    if ((this.operation?.address?.firm ?? '') == ""
      && (this.operation?.address?.street ?? '') == ""
      && (this.operation?.address?.street_number ?? '') == ""
      && (this.operation?.address?.floor ?? '') == ""
      && (this.operation?.address?.door_number ?? '') == ""
      && (this.operation?.address?.postcode ?? '') == ""
      && (this.operation?.address?.city ?? '') == ""
      && (this.operation?.address?.country ?? '') == ""
      && (this.operation?.address?.free_text ?? '') == "") return ""

    let result = "";

    if (!justStreetAddress && (this.operation?.address?.firm ?? "") != "") result += this.operation?.address?.firm

    if ((this.operation?.address?.street ?? "") != "") {
      if (result != "") result += separator
      result += this.operation?.address?.street

      if ((this.operation?.address?.street_number ?? "") != "") {
        result += " " + this.operation?.address?.street_number

        if (!justStreetAddress && (this.operation?.address?.floor ?? "") != "") {
          result += ("/") + this.operation?.address?.floor
        }

        if (!justStreetAddress && (this.operation?.address?.door_number ?? "") != "") {
          result += ("/") + this.operation?.address?.door_number
        }
      }
    }

    if (result != "" && ((this.operation?.address?.postcode ?? "") != "" || (this.operation?.address?.city ?? "") != ""))
      result += separator

    result += (this.operation?.address?.postcode ?? "")

    if ((this.operation?.address?.city ?? "") != "") {
      if ((this.operation?.address?.postcode ?? "") != "") result += " "
      result += this.operation?.address?.city
    }

    if ((this.operation?.address?.country ?? "") != "") {
      if (result != "") result += separator
      result += this.operation?.address?.country
    }

    if ((this.operation?.address?.free_text ?? "") != "") {
      if (result != "") result += separator
      result += this.operation?.address?.free_text
    }

    return result;
  }

  private loadWaterSources() {
    this.mapClearWaterSources();

    this.apiService.operationWaterSources({
      organization_id: `${this.organizationService.selectedOrganizationId}`,
      operation_id: this.operation!!.id
    }, response => {
      response.items.forEach(x => {
        let addressMarker = L.marker({lat: x.lat, lng: x.lng}, {
          draggable: false,
          /*icon: L.icon({
            iconUrl: 'assets/ic_my_location.png',
            iconSize: [40, 40]
          }),*/
          icon: L.icon({
            iconUrl: x.icon?.url ?? "",
            iconSize: [x.icon?.width ?? 0, x.icon?.height ?? 0],
            iconAnchor: [x.icon?.anchor_x ?? 0, x.icon?.anchor_y ?? 0]
          }),
          /*icon: new L.DivIcon({
            iconSize: [22, 22],
            iconAnchor: [11, 11],
            className: 'map-object-marker',
            html: `<img src="${url}">`
          }),*/
          zIndexOffset: 100
        });
        addressMarker.bindPopup(L.popup({closeButton: false}).setLatLng({
          lat: x.lat,
          lng: x.lng
        }).setContent(`<b>${x.name}</b><br>Adresse: ${x.address}<br>Anschlüsse: ${x.connections}<br>Zufahrt: ${x.driveway}<br>Zufluss (l/min): ${x.flowrate}<br>Entfernung: ${x.distance_in_meters ?? 0}m<br>Nenndurchmesser (mm): ${x.nominal_diameter}`));
        addressMarker.addTo(this.layerGroupWaterSources!);
      });
    }, error => {

    }, () => {
    })
  }

  private mapClearWaterSources(): void {
    this.layerGroupWaterSources?.clearLayers();
  }

  private startProtocolMessageSyncInterval(): void {
    if (this.protocolMessageSyncInterval != null) {
      clearInterval(this.protocolMessageSyncInterval);
    }

    let interval = 5 * 1000; // every 5s

    this.protocolMessageSyncInterval = setInterval(() => {
      this.loadProtocolMessages();
    }, interval);
  }

  private loadProtocolMessages(scrollToBottom: boolean = false, isInitial: boolean = false): void {
    this.apiService.getOperationProtocolMessages(`${this.organizationService.selectedOrganizationId}`, this.operation!!.id, response => {
      let countChanged = this.protocolMessages.length != response.entries.length;
      this.protocolMessages = response.entries;
      if (scrollToBottom || countChanged) {
        setTimeout(() => {
          this.protocolList!.nativeElement!.scrollTop = this.protocolList?.nativeElement.scrollHeight;
        }, 0);
      }

      if (!isInitial && countChanged) {
        this._snackBar.open("Neuer Protokolleintrag", '');
      }
    }, error => {

    }, () => {
    })
  }

  private postProtocolMessage(message: string): void {
    if (this.isSendingProtocolMessage) return;

    this.isSendingProtocolMessage = true;

    this.apiService.postOperationProtocolMessages(`${this.organizationService.selectedOrganizationId}`, this.operation!!.id, message, () => {
      this.loadProtocolMessages(true);
      this.newMessage = '';
      this.isSendingProtocolMessage = false;
    }, error => {
      alert("Fehler beim Senden der Nachricht! Bitte versuche es später nochmal.")
      this.isSendingProtocolMessage = false;
    }, () => {
      this.isSendingProtocolMessage = false;
    })
  }

  onSendProtocolMessageClick(event: any): void {
    if (this.newMessage.trim() == "") return;

    this.postProtocolMessage(this.newMessage);
  }

  onProtocolMessageKeyDown(event: KeyboardEvent): void {
    if (event.code === 'Enter') {
      this.postProtocolMessage(this.newMessage);
    }
  }
}
