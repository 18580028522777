<main>
  <div class="body-content-centered">
    <nav class="body-content-header-nav">
      <div class="body-content-header-nav-title">Anbindungen</div>
    </nav>

    <div class="loading" *ngIf="isLoading; else items">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <ng-template #items>
      <ul class="items">
        <li *ngFor="let binding of bindings" (click)="openBinding(binding.key)">
          <div class="data">
            <div class="title">{{ binding.title }}</div>
            <div class="status">{{ binding.status_text }}</div>
          </div>
          <div class="indicator"><span [ngClass]="'status-' + binding.status"></span></div>
        </li>
      </ul>

      <div *ngIf="bindings.length <= 0" id="empty">
        Keine Anbindungen verfügbar.
      </div>
    </ng-template>

    <div id="support-info" *ngIf="!isLoading">
      <b>Keine für dich dabei?</b><br>
      Schreibe uns an <a href="mailto:support@smartpager.at">support@smartpager.at</a>, damit wir mehr über deine
      Anforderungen erfahren und entsprechende Lösungen finden.
    </div>
  </div>
</main>
