<main>
  <div class="body-content-centered">
    <nav class="body-content-header-nav">
      <div class="body-content-header-nav-title">Externe Kalender</div>
    </nav>

    <div>
      <p>Synchronisiere Termine externer Kalender über das iCal-Format. Die Synchronisation erfolgt alle 10 Minuten.</p>
      <!--<p><a href="https://support.smartpager.at/api/" target="_blank">Dokumentation der Schnittstelle</a></p>-->
    </div>

    <div id="new-key">
      <mat-form-field appearance="fill" color="accent" id="input-title">
        <mat-label>Name des Kalenders</mat-label>
        <input matInput type="text" placeholder="" [(ngModel)]="calendarName" required>
      </mat-form-field>
      <mat-form-field appearance="fill" color="accent" id="input-url">
        <mat-label>Webadresse</mat-label>
        <input matInput type="url" placeholder="" [(ngModel)]="calendarUrl" required>
      </mat-form-field>
      <app-action-button [disabled]="isSaving" (click)="addCalendar()" iconKey="add">Hinzufügen</app-action-button>
    </div>

    <h2>AKTIVIERTE KALENDER</h2>

    <div class="loading" *ngIf="isLoading; else content">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <ng-template #content>
      <div id="content">
        <div id="list-empty" *ngIf="externalICalendars.length <= 0; else list">Keine aktivierten Kalender vorhanden.
        </div>

        <ng-template #list>
          <ul id="list">
            <li *ngFor="let t of externalICalendars">
              <div class="list-details">
                <div class="list-title">{{ t.name }}</div>
                <div class="list-subtitle">Letzte Synchronisation:
                  <span *ngIf="t.time_last_sync != null">{{ t.time_last_sync * 1000 | date: 'dd.MM.yyyy HH:mm' }}</span>
                  <span *ngIf="t.time_last_sync == null">Noch nie</span>
                </div>
                <div class="list-details">
                  Benachrichtigungen: {{ t.send_notifications ? 'aktiv' : 'inaktiv' }} |
                  Erinnerungen: {{ t.event_reminder_schedule_type_keys.length }} | Nur markierte
                  Termine: {{ t.sync_tagged_events_only ? 'aktiv' : 'inaktiv' }}
                </div>
              </div>
              <div class="list-actions">
                <button mat-icon-button (click)="onItemEditClick($event, t)" matTooltip="Bearbeiten"
                        matTooltipPosition="right">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
            </li>
          </ul>
        </ng-template>
      </div>
    </ng-template>
  </div>
</main>
