<main>
  <div id="logo" [routerLink]="'/home'">
    <img src="assets/logo_dashboard.svg" alt="Dashboard">
  </div>
  <nav>
    <ul>
      <li routerLink="/home/operations" routerLinkActive="active">Einsätze</li>
      <li routerLink="/home/events" routerLinkActive="active">Ereignisse</li>
      <li routerLink="/home/members" routerLinkActive="active" *ngIf="isAdministrator()">Mitglieder</li>
    </ul>
  </nav>
  <div id="actions">
    <ul>
      <li title="Neuigkeiten" routerLink="/home/news" routerLinkActive="active">
        <img src="assets/icons/inbox_24dp_000000_FILL1_wght400_GRAD0_opsz24.svg">
      </li>
      <li title="Einstellungen" routerLink="/home/settings" routerLinkActive="active" *ngIf="isAdministrator()">
        <img src="assets/icons/settings_24dp_000000_FILL1_wght400_GRAD0_opsz24.svg">
      </li>
    </ul>
  </div>
  <div id="organization" (click)="onOrganizationClick($event)">
    {{ getSelectedOrganization()?.name ?? "-" }}
  </div>
  <div id="profile">
    <img src="assets/images/user-icon.webp" [matMenuTriggerFor]="profileMenu">
  </div>

  <mat-menu #profileMenu="matMenu">
    <button mat-menu-item [disabled]="true">
      <span>Hallo {{ firstName }}!</span>
    </button>
    <button mat-menu-item (click)="onSignOutClick($event)" [disabled]="isSigningOut">
      <span>Abmelden</span>
    </button>
  </mat-menu>
</main>
