<main>
  <div class="body-content-centered">
    <nav class="body-content-header-nav">
      <div class="body-content-header-nav-title">Zahlungsmethode aktualisieren</div>
    </nav>

    <div id="loading" *ngIf="isLoading">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <div id="content" [ngClass]="isLoading ? 'hide' : 'show'">
      <div id="invoice-address" *ngIf="invoiceAddress">
        <h3>Rechnungsadresse</h3>
        <mat-form-field appearance="fill" color="accent">
          <mat-label>Name / Firma</mat-label>
          <input matInput type="text" placeholder="" [disabled]="isSaving" [(ngModel)]="invoiceAddress.name" required>
        </mat-form-field>
        <mat-form-field appearance="fill" color="accent">
          <mat-label>Straße</mat-label>
          <input matInput type="text" placeholder="" [disabled]="isSaving" [(ngModel)]="invoiceAddress.street" required>
        </mat-form-field>
        <div class="inline">
          <mat-form-field appearance="fill" color="accent">
            <mat-label>PLZ</mat-label>
            <input matInput type="text" placeholder="" [disabled]="isSaving"
                   [(ngModel)]="invoiceAddress.postal_code" required>
          </mat-form-field>
          <mat-form-field appearance="fill" color="accent">
            <mat-label>Ort</mat-label>
            <input matInput type="text" placeholder="" [disabled]="isSaving" [(ngModel)]="invoiceAddress.city" required>
          </mat-form-field>
        </div>
        <mat-form-field appearance="fill">
          <mat-label>Land</mat-label>
          <mat-select [disabled]="isSaving" [(ngModel)]="invoiceAddress.country_code" required>
            <mat-option [value]="country.code" *ngFor="let country of countries">{{ country.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" color="accent">
          <mat-label>UID</mat-label>
          <input matInput type="text" placeholder="" [disabled]="isSaving" [(ngModel)]="invoiceAddress.vat_id">
          <mat-hint>Umsatzsteuer-Identifikationsnummer</mat-hint>
        </mat-form-field>
      </div>

      <div id="invoice-email-receiver" *ngIf="invoiceAddress">
        <h3>Rechnung senden an</h3>
        <mat-form-field appearance="fill" color="accent">
          <mat-label>E-Mail-Adresse</mat-label>
          <input matInput type="email" placeholder="" [disabled]="isSaving" [(ngModel)]="invoiceAddress.email_address"
                 required>
        </mat-form-field>
      </div>

      <div id="payment-method">
        <h3>Zahlungsmethode</h3>

        <mat-radio-group [disabled]="isSaving || (invoiceAddress?.is_e_rechnung_enabled ?? false)"
                         [(ngModel)]="paymentMethodType"
                         id="method-select">
          <mat-radio-button value="sepa" *ngIf="paymentMethod != null">
            <div id="sepa">
              <div class="pm">
                <div class="payment-method-icon"></div>
                <div class="payment-method-title">•••• {{ paymentMethod.last4 }}</div>
              </div>
              <div class="description">{{ paymentMethod.name }} ({{ paymentMethod.email }})</div>
            </div>
          </mat-radio-button>
          <mat-radio-button value="sepa_new">
            {{ paymentMethod != null ? 'Bankkonto ändern' : 'SEPA Lastschrift' }}
          </mat-radio-button>
          <mat-radio-button value="invoice">Kauf auf Rechnung</mat-radio-button>
          <mat-radio-button value="e-rechnung" *ngIf="false">e-Rechnung</mat-radio-button>
        </mat-radio-group>

        <div *ngIf="paymentMethodType == 'invoice'" class="payment-method-content">
          Rechnungen zahlbar innerhalb von 30 Tagen ohne Abzug
        </div>

        <div *ngIf="paymentMethodType == 'e-rechnung'" class="payment-method-content">
          Rechnung an den Bund Österreich
          <p *ngIf="invoiceAddress?.is_e_rechnung_enabled ?? false">Um e-Rechnung zu deaktivieren, kontaktiere bitte
            unseren Support unter <a href="mailto:support@smartpager.at">support@smartpager.at</a>.</p>
        </div>

        <div [ngClass]="paymentMethodType == 'sepa_new' ? 'show' : 'hide'"
             class="payment-method-content">
          <mat-form-field appearance="fill" color="accent">
            <mat-label>Name des Kontoinhabers</mat-label>
            <input matInput placeholder="" [disabled]="isSaving" [(ngModel)]="bankAccountName" required>
          </mat-form-field>

          <mat-form-field appearance="fill" color="accent">
            <mat-label>E-Mail-Adresse</mat-label>
            <input matInput type="email" placeholder="" [disabled]="isSaving" [(ngModel)]="bankAccountEmail" required>
          </mat-form-field>

          <div id="sepa-iban">
            <div id="iban-element" #ibanElement></div>
          </div>
          <div id="sepa-bank-name" *ngIf="bankName != '' && bankName != null">{{ bankName }}</div>
          <div id="error-message" role="alert" *ngIf="sepaErrorMessage != '' && sepaErrorMessage != null">
            Fehler: {{ sepaErrorMessage }}
          </div>

          <div id="mandate-acceptance">
            <label>
              <input type="checkbox" [(ngModel)]="acceptSepaConditions" [disabled]="isSaving"/>
              Ich ermächtige (A) Elias Lexl und Stripe, seinen Zahlungsdienstleister, Zahlungen von meinem Konto mittels
              Lastschrift einzuziehen. Zugleich (B) weise ich mein Kreditinstitut gemäß diesen Anweisungen an, die auf
              mein Konto gezogenen Lastschriften einzulösen. Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit
              dem Belastungsdatum, die Erstattung des belasteten Betrages verlagen. Es gelten dabei die mit meinem
              Kreditinstitut vereinbarten Bedingungen.
            </label>
          </div>
        </div>

        <div id="actions">
          <button class="btn-action btn-primary"
                  [disabled]="isSaving || (paymentMethodType == 'sepa_new' && !acceptSepaConditions)"
                  (click)="update()">
            Aktualisieren
          </button>
          <button class="btn-action btn-secondary" [disabled]="isSaving" (click)="navigateBack()">
            Zurück
          </button>
        </div>

        <div id="legal-notice">
          Durch das Hinzufügen einer Zahlungsmethode stimmst du den
          <a href="https://www.smartpager.at/terms" target="_blank"> allgemeinen Geschäftsbedingungen</a> und der
          <a href="https://www.smartpager.at/privacy" target="_blank">Datenschutzerklärung</a> von Elias Lexl zu.
        </div>
      </div>
    </div>
  </div>
</main>
