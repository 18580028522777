<main>
  <div class="body-content-centered">
    <nav class="body-content-header-nav">
      <div class="body-content-header-nav-title">Plan verlängern</div>
    </nav>

  <div id="content">
    <h2>AKTUELLER PLAN</h2>

    <div class="loading" *ngIf="isLoading || isLoadingPricing; else content">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <ng-template #content>
      <div id="plan" *ngIf="license != null">
        <div id="plan-title">{{license!.title}}</div>
        <div id="plan-pricing">
          ab {{price?.price_plan}} / {{price!.billing_period == 'month' ? 'Monat' : 'Jahr'}}
        </div>
        <div id="plan-cancel-info">
          <p>Dieser Plan wird nicht länger gekündigt, sondern am {{license!.time_end * 1000 | date: 'dd. MMMM yyyy'}}
            erneuert.</p>
        </div>
      </div>

      <button class="btn-action btn-primary" [disabled]="isWorking" (click)="onSubmit()">
        Plan verlängern
      </button>
      <button class="btn-action btn-secondary" [disabled]="isWorking" [routerLink]="['/home/settings/billing']">
        Zurück
      </button>

      <div id="legal-notice">Durch die Verlängerng deines neuen Plans stimmst du den
        <a href="https://www.smartpager.at/terms" target="_blank">allgemeinen Geschäftsbedingungen</a> und der
        <a href="https://www.smartpager.at/privacy" target="_blank">Datenschutzerklärung</a> von Elias Lexl zu.
      </div>
    </ng-template>
  </div>
  </div>
</main>
