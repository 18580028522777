<main>
  <div class="body-content-centered">
    <nav class="body-content-header-nav">
      <div class="body-content-header-nav-title">Schnittstelle</div>
    </nav>

    <div>
      <p>Verbinde andere Applikationen mit SmartPager und erstelle automatisch neue Einsätze. Hier kannst du Schlüssel
        für
        deine Applikationen erstellen.</p>
      <p><a href="https://support.smartpager.at/api/" target="_blank">Dokumentation der Schnittstelle</a>
      </p>
    </div>

    <div id="new-key">
      <mat-form-field appearance="fill" color="accent" id="input-title">
        <mat-label>Titel des Schlüssels</mat-label>
        <input matInput type="text" placeholder="" [(ngModel)]="tokenTitle" required>
      </mat-form-field>
      <app-action-button [disabled]="isSaving" (click)="addToken()">Hinzufügen</app-action-button>
    </div>

    <div id="preview" *ngIf="tokenPreview != null">
      <div id="preview-title">Neuer Schlüssel "{{ tokenPreview?.title }}"</div>
      <div id="preview-token">{{ tokenPreview?.token || "-" }}</div>
      <div id="preview-warning">Speichere diesen Schlüssel, da er nur einmal angezeigt werden kann.</div>
    </div>

    <h2>AKTIVE SCHLÜSSEL</h2>

    <div class="loading" *ngIf="isLoading; else content">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <ng-template #content>
      <div id="content">
        <div id="list-empty" *ngIf="tokens.length <= 0; else list">Keine aktiven Schlüssel vorhanden.</div>

        <ng-template #list>
          <ul id="list">
            <li *ngFor="let t of tokens">
              <div class="list-details">
                <div class="list-title">{{ t.title }}</div>
                <div class="list-subtitle">{{ t.time * 1000 | date: 'dd.MM.yyyy HH:mm' }}</div>
              </div>
              <div class="list-actions">
                <button mat-icon-button (click)="deleteToken(t.id)" matTooltip="Löschen" matTooltipPosition="right">
                  <mat-icon>clear</mat-icon>
                </button>
              </div>
            </li>
          </ul>
        </ng-template>
      </div>
    </ng-template>
  </div>
</main>
