<main>
  <div class="body-content-centered">
    <nav class="body-content-header-nav">
      <div class="body-content-header-nav-title">Daten aktualisieren</div>
    </nav>

    <div [ngSwitch]="true">
      <div id="loading"
           *ngSwitchCase="!hasError && (isLoadingInformation || isLoadingOrganizationTypes || isLoadingOrganizationTypes)">
        <mat-spinner color="accent" diameter="32"></mat-spinner>
      </div>

      <div id="content"
           *ngSwitchCase="!isLoadingInformation && !isLoadingOrganizationTypes && !isLoadingOrganizationTypes">
        <h2>ORGANISATION</h2>
        <mat-form-field appearance="fill" color="accent">
          <mat-label>Typ</mat-label>
          <input matInput type="text" placeholder="" [ngModel]="organizationType" disabled>
          <!--<mat-hint>Für die Änderung des Typs, schreibe uns bitte an <a
            href="mailto:support@smartpager.at">support@smartpager.at</a></mat-hint>-->
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill" color="accent">
          <mat-label>Bezeichnung der Organisation</mat-label>
          <input matInput type="text" placeholder="" [(ngModel)]="organizationName" required>
        </mat-form-field>

        <h2>KONTAKT</h2>
        <mat-form-field appearance="fill" color="accent">
          <mat-label>E-Mail-Adresse</mat-label>
          <input matInput type="text" placeholder="" [(ngModel)]="organizationEmail" required>
        </mat-form-field>
        <mat-form-field appearance="fill" color="accent">
          <mat-label>Telefon</mat-label>
          <input matInput type="text" placeholder="" [(ngModel)]="organizationPhone" required>
        </mat-form-field>

        <h2>ANSCHRIFT</h2>
        <mat-form-field appearance="fill" color="accent">
          <mat-label>Name / Firma</mat-label>
          <input matInput type="text" placeholder="" [(ngModel)]="addressName" required>
        </mat-form-field>
        <mat-form-field appearance="fill" color="accent">
          <mat-label>Straße</mat-label>
          <input matInput type="text" placeholder="" [(ngModel)]="addressStreet" required>
        </mat-form-field>
        <mat-form-field appearance="fill" color="accent">
          <mat-label>Postleitzahl</mat-label>
          <input matInput type="text" placeholder="" [(ngModel)]="addressPostcode" required>
        </mat-form-field>
        <mat-form-field appearance="fill" color="accent">
          <mat-label>Ort</mat-label>
          <input matInput type="text" placeholder="" [(ngModel)]="addressCity" required>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Land</mat-label>
          <mat-select [(ngModel)]="addressCountryId" required>
            <mat-option [value]="country.id" *ngFor="let country of countries">{{ country.name }}</mat-option>
          </mat-select>
        </mat-form-field>

        <div id="actions">
          <app-action-button [disabled]="isSaving" iconKey="done" (click)="save()">
            Speichern
          </app-action-button>
        </div>
      </div>

      <div *ngSwitchCase="hasError">
        Fehler beim Laden! Bitte lade die Seite neu und kontaktiere unseren Support, sollte das Problem weiterhin
        bestehen.
      </div>
    </div>
  </div>
</main>
