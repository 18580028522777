<main>
  <div class="body-content-centered">
    <nav class="body-content-header-nav">
      <div class="body-content-header-nav-title">E-Mail Einladung</div>
    </nav>

    <div id="form">
      <div id="content">
        <mat-form-field appearance="fill">
          <mat-label>E-Mail-Adresse</mat-label>
          <input matInput required type="email" [(ngModel)]="email" [disabled]="isProcessing">
        </mat-form-field>

        <app-action-button [disabled]="isProcessing" iconKey="done" (click)="sendInvitation()">
          Einladung senden
        </app-action-button>
      </div>
    </div>
  </div>
</main>
