import {Injectable, isDevMode} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Subscription} from "rxjs";
import {ApiUrl} from "../network/ApiUrl";
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {OperationListRequest} from "../network/request/OperationListRequest";
import {OperationListResponse} from "../network/response/OperationListResponse";
import {OperationCreateRequest} from "../network/request/OperationCreateRequest";
import {OperationCreateResponse} from "../network/response/OperationCreateResponse";
import {OperationDetailRequest} from "../network/request/OperationDetailRequest";
import {OperationDetailResponse} from "../network/response/OperationDetailResponse";
import {OperationWaterSourcesRequest} from "../network/request/OperationWaterSourcesRequest";
import {OperationWaterSourcesResponse} from "../network/response/OperationWaterSourcesResponse";
import {BindingConnectPrintTestPageRequest} from "../network/request/BindingConnectPrintTestPageRequest";
import {MemberAddSignUpRequest} from "../network/request/MemberAddSignUpRequest";
import {MemberListRequest} from "../network/request/MemberListRequest";
import {MemberListResponse} from "../network/response/MemberListResponse";
import {MemberAddEmailInvitationRequest} from "../network/request/MemberAddEmailInvitationRequest";
import {EventCreateRequest} from "../network/request/EventCreateRequest";
import {EventCreateResponse} from "../network/response/EventCreateResponse";
import {EventListRequest} from "../network/request/EventListRequest";
import {EventListResponse} from "../network/response/EventListResponse";
import {MonitorListRequest} from "../network/request/MonitorListRequest";
import {MonitorListResponse} from "../network/response/MonitorListResponse";
import {MonitorDetailsRequest} from "../network/request/MonitorDetailsRequest";
import {MonitorDetailsResponse} from "../network/response/MonitorDetailsResponse";
import {NewsRoadmapRequest} from "../network/request/NewsRoadmapRequest";
import {NewsRoadmapResponse} from "../network/response/NewsRoadmapResponse";
import {ActionNotificationGetResponse} from "../network/response/ActionNotificationGetResponse";
import {PostRecipientRequest} from "../network/request/PostRecipientRequest";
import {ActionNotificationRecipient} from "../models/ActionNotificationRecipient";
import {IntegrationExternalICalendarUpdateRequest} from "../network/request/IntegrationExternalICalendarUpdateRequest";
import {OperationProtocolMessagesResponse} from "../network/response/OperationProtocolMessagesResponse";
import {PostProtocolMessageRequest} from "../network/request/PostProtocolMessageRequest";
import {WasserkarteFeaturesResponse} from "../network/response/WasserkarteFeaturesResponse";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public readonly ERROR_CONNECTION = 'connection';

  constructor(private httpClient: HttpClient) {
  }

  public operationList(request: OperationListRequest, onSuccess: (response: OperationListResponse) => any, onError: (error: string) => any, doAlways: () => any): Subscription {
    return this.httpClient
      .post(ApiService.prepareURL(ApiUrl.OPERATION__LIST), request, {headers: ApiService.getHeader(true)})
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as OperationListResponse);
          } else {
            if (!ApiService.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error.error.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public wasserkarteFeatures(onSuccess: (response: WasserkarteFeaturesResponse) => any, onError: (error: string) => any, doAlways: () => any): Subscription {
    return this.httpClient
      .get(ApiService.prepareURL(ApiUrl.WASSERKARTE__FEATURES), {headers: ApiService.getHeader(true)})
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as WasserkarteFeaturesResponse);
          } else {
            if (!ApiService.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error.error.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public operationDetail(request: OperationDetailRequest, onSuccess: (response: OperationDetailResponse) => any, onError: (error: string) => any, doAlways: () => any): Subscription {
    return this.httpClient
      .post(ApiService.prepareURL(ApiUrl.OPERATION__DETAIL), request, {headers: ApiService.getHeader(true)})
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as OperationDetailResponse);
          } else {
            if (!ApiService.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error.error.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public operationCreate(request: OperationCreateRequest, onSuccess: (response: OperationCreateResponse) => any, onError: (error: string) => any, doAlways: () => any): Subscription {
    return this.httpClient
      .post(ApiService.prepareURL(ApiUrl.OPERATION__CREATE), request, {headers: ApiService.getHeader(true)})
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as OperationCreateResponse);
          } else {
            if (!ApiService.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error.error.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public operationEnd(organizationId: string, operationId: string, onSuccess: (response: OperationCreateResponse) => any, onError: (error: string) => any, doAlways: () => any): Subscription {
    let preparedUrl = ApiService.prepareURL(ApiUrl.OPERATION__ID__END);
    preparedUrl = preparedUrl.replace("{id}", operationId);
    let url = `${preparedUrl}?organizationId=${organizationId}`;

    return this.httpClient
      .post(url, {}, {headers: ApiService.getHeader(true)})
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as OperationCreateResponse);
          } else {
            if (!ApiService.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error.error.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public eventList(request: EventListRequest, onSuccess: (response: EventListResponse) => any, onError: (error: string) => any, doAlways: () => any): Subscription {
    return this.httpClient
      .post(ApiService.prepareURL(ApiUrl.EVENT__LIST), request, {headers: ApiService.getHeader(true)})
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as EventListResponse);
          } else {
            if (!ApiService.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error.error.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public eventCreate(request: EventCreateRequest, onSuccess: (response: EventCreateResponse) => any, onError: (error: string) => any, doAlways: () => any): Subscription {
    return this.httpClient
      .post(ApiService.prepareURL(ApiUrl.EVENT__CREATE), request, {headers: ApiService.getHeader(true)})
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as EventCreateResponse);
          } else {
            if (!ApiService.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error.error.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public operationWaterSources(request: OperationWaterSourcesRequest,
                               onSuccess: (response: OperationWaterSourcesResponse) => any,
                               onError: (error: string) => any,
                               doAlways: () => any): Subscription {

    return this.httpClient
      .post(ApiService.prepareURL(ApiUrl.OPERATION__WATER_SOURCES), request, {headers: ApiService.getHeader(true)})
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as OperationWaterSourcesResponse);
          } else {
            if (!ApiService.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error.error.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public signOut(onSuccess: () => any, onError: (error: string) => any, doAlways: () => any): Subscription {
    return this.httpClient
      .post(ApiService.prepareURL(ApiUrl.SIGN__OUT), {}, {headers: ApiService.getHeader(true)})
      .pipe(map(response => response as any))
      .subscribe(response => {
        if (response.status === 200) {
          onSuccess();
        } else {
          if (!ApiService.handleCommonError(response.error)) {
            onError(response.error);
          }
        }
      }, error => {
        this.handleError(error.error.error, onError);
      }, () => {
        doAlways();
      })
  }

  public bindingConnectPrintTestPage(request: BindingConnectPrintTestPageRequest,
                                     onSuccess: () => any,
                                     onError: (error: string) => any,
                                     doAlways: () => any): Subscription {
    return this.httpClient
      .post(ApiService.prepareURL(ApiUrl.BINDINGS__CONNECT__PRINT__TEST_PAGE), request, {headers: ApiService.getHeader(true)})
      .pipe(map(response => response as any))
      .subscribe(response => {
        if (response.status === 200) {
          onSuccess();
        } else {
          if (!ApiService.handleCommonError(response.error)) {
            onError(response.error);
          }
        }
      }, error => {
        this.handleError(error.error.error, onError);
      }, () => {
        doAlways();
      })
  }

  public memberAddSignUp(request: MemberAddSignUpRequest, onSuccess: () => any, onError: (error: string) => any, doAlways: () => any): Subscription {
    return this.httpClient
      .post(ApiService.prepareURL(ApiUrl.MEMBER__ADD__SIGN_UP), request, {headers: ApiService.getHeader(true)})
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!ApiService.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error.error.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public monitorList(request: MonitorListRequest,
                     onSuccess: (response: MonitorListResponse) => any,
                     onError: (error: string) => any,
                     doAlways: () => any): Subscription {

    return this.httpClient
      .post(ApiService.prepareURL(ApiUrl.MONITOR__LIST), request, {headers: ApiService.getHeader(true)})
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as MonitorListResponse);
          } else {
            if (!ApiService.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error.error.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public monitorDetails(request: MonitorDetailsRequest,
                        onSuccess: (response: MonitorDetailsResponse) => any,
                        onError: (error: string) => any,
                        doAlways: () => any): Subscription {

    return this.httpClient
      .post(ApiService.prepareURL(ApiUrl.MONITOR__DETAILS), request, {headers: ApiService.getHeader(true)})
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as MonitorDetailsResponse);
          } else {
            if (!ApiService.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error.error.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public memberList(request: MemberListRequest,
                    onSuccess: (response: MemberListResponse) => any,
                    onError: (error: string) => any,
                    doAlways: () => any): Subscription {

    return this.httpClient
      .post(ApiService.prepareURL(ApiUrl.MEMBER__LIST), request, {headers: ApiService.getHeader(true)})
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as MemberListResponse);
          } else {
            if (!ApiService.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error.error.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public memberAddEmailInvitation(request: MemberAddEmailInvitationRequest, onSuccess: () => any, onError: (error: string) => any, doAlways: () => any): Subscription {
    return this.httpClient
      .post(ApiService.prepareURL(ApiUrl.MEMBER__ADD__EMAIL_INVITATION), request, {headers: ApiService.getHeader(true)})
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!ApiService.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error.error.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public newsRoadmap(request: NewsRoadmapRequest,
                     onSuccess: (response: NewsRoadmapResponse) => any,
                     onError: (error: string) => any,
                     doAlways: () => any): Subscription {

    return this.httpClient
      .post(ApiService.prepareURL(ApiUrl.NEWS__ROADMAP), request, {headers: ApiService.getHeader(true)})
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as NewsRoadmapResponse);
          } else {
            if (!ApiService.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error.error.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public actionNotificationGetAll(organizationId: string,
                                  onSuccess: (response: ActionNotificationGetResponse) => any,
                                  onError: (error: string) => any,
                                  doAlways: () => any): Subscription {

    let url = `${ApiService.prepareURL(ApiUrl.ACTION_NOTIFICATION)}?organizationId=${organizationId}`;

    return this.httpClient
      .get(url, {headers: ApiService.getHeader(true)})
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as ActionNotificationGetResponse);
          } else {
            if (!ApiService.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error.error.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public getOperationProtocolMessages(organizationId: string, operationId: string,
                                      onSuccess: (response: OperationProtocolMessagesResponse) => any,
                                      onError: (error: string) => any,
                                      doAlways: () => any): Subscription {

    let preparedUrl = ApiService.prepareURL(ApiUrl.OPERATION__ID__PROTOCOL__MESSAGE);
    preparedUrl = preparedUrl.replace("{id}", operationId);
    let url = `${preparedUrl}?organizationId=${organizationId}`;

    return this.httpClient
      .get(url, {headers: ApiService.getHeader(true)})
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as OperationProtocolMessagesResponse);
          } else {
            if (!ApiService.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error.error.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public postOperationProtocolMessages(organizationId: string, operationId: string, message: string,
                                       onSuccess: () => any,
                                       onError: (error: string) => any,
                                       doAlways: () => any): Subscription {

    let preparedUrl = ApiService.prepareURL(ApiUrl.OPERATION__ID__PROTOCOL__MESSAGE);
    preparedUrl = preparedUrl.replace("{id}", operationId);
    let url = `${preparedUrl}?organizationId=${organizationId}`;

    let request = {
      message: message
    } as PostProtocolMessageRequest;

    return this.httpClient
      .post(url, request, {headers: ApiService.getHeader(true)})
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!ApiService.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error.error.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public actionNotificationAdd(organizationId: string,
                               key: string,
                               recipient: ActionNotificationRecipient,
                               onSuccess: () => any,
                               onError: (error: string) => any,
                               doAlways: () => any): Subscription {

    let url = `${ApiService.prepareURL(ApiUrl.ACTION_NOTIFICATION)}/${key}/recipient?organizationId=${organizationId}`;
    let request = {
      notice: recipient.notice,
      transmission_type: recipient.transmission_type,
      address: recipient.address,
      options: recipient.options
    } as PostRecipientRequest;

    return this.httpClient
      .post(url, request, {headers: ApiService.getHeader(true)})
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!ApiService.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error.error.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public actionNotificationDelete(organizationId: string, key: string, id: string,
                                  onSuccess: () => any,
                                  onError: (error: string) => any,
                                  doAlways: () => any): Subscription {

    let url = `${ApiService.prepareURL(ApiUrl.ACTION_NOTIFICATION)}/${key}/recipient/${id}?organizationId=${organizationId}`;

    return this.httpClient
      .delete(url, {headers: ApiService.getHeader(true)})
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!ApiService.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error.error.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public integrationExternalICalendarUpdate(organizationId: string,
                                            externalICalendarId: string,
                                            name: string,
                                            sendNotifications: boolean,
                                            syncTaggedEventsOnly: boolean,
                                            userSelectionType: string | null,
                                            eventReminderScheduleTypeKeys: string[],
                                            onSuccess: () => any,
                                            onError: (error: string) => any,
                                            doAlways: () => any): Subscription {

    let preparedUrl = ApiService.prepareURL(ApiUrl.INTEGRATION__EXTERNAL_ICALENDAR__UPDATE);
    preparedUrl = preparedUrl.replace("{id}", externalICalendarId);
    let url = `${preparedUrl}?organizationId=${organizationId}`;

    let request = {
      name: name,
      send_notifications: sendNotifications,
      sync_tagged_events_only: syncTaggedEventsOnly,
      event_reminder_schedule_type_keys: eventReminderScheduleTypeKeys,
      user_selection_type: userSelectionType
    } as IntegrationExternalICalendarUpdateRequest;

    return this.httpClient
      .post(url, request, {headers: ApiService.getHeader(true)})
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!ApiService.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error.error.error, onError);
        },
        () => {
          doAlways();
        });
  }

  private static getHeader(withCredentials: boolean = false): any {
    if (withCredentials) {
      return new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      });
    }

    return {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
  }

  private static prepareURL(url: string): string {
    return (isDevMode() ? ApiUrl.DOMAIN_DEV : (environment.isDev ? ApiUrl.DOMAIN_TEST : ApiUrl.DOMAIN_PROD)) + url;
  }

  private handleError(error: any, onError: (error: string) => any): void {
    onError(error !== undefined && error != null && error !== ''
      ? error
      : this.ERROR_CONNECTION);
  }

  private static handleCommonError(error: string): boolean {
    console.log(`### handleCommonError: ${error}`);
    switch (error) {
      case 'jwt_invalid':
      case 'jwt_expired':
      case 'device_logged_out':
        localStorage.clear();
        window.location.reload(); // todo
        return true;
    }
    return false;
  }
}
