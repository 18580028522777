<main>
  <div class="body-content-centered">
    <nav class="body-content-header-nav">
      <div class="body-content-header-nav-title">Mitglied registrieren</div>
    </nav>

    <div id="form">
      <div id="content">
        <mat-form-field appearance="fill">
          <mat-label>Vorname</mat-label>
          <input matInput required [(ngModel)]="firstName" [disabled]="isProcessing">
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Nachname</mat-label>
          <input matInput required [(ngModel)]="lastName" [disabled]="isProcessing">
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>E-Mail-Adresse</mat-label>
          <input matInput required type="email" [(ngModel)]="email" [disabled]="isProcessing">
        </mat-form-field>

        <mat-checkbox [(ngModel)]="hasAcceptedTerms" [disabled]="isProcessing">
          Ich bestätige, dass ich die Erlaubnis des Mitglieds habe, die Registrierung durchzuführen.
        </mat-checkbox>

        <p><br>Nach der Registrierung erhält das Mitglied eine E-Mail mit den nächsten Schritten.</p>

        <app-action-button [disabled]="!hasAcceptedTerms || isProcessing" iconKey="done" (click)="signUpMember()">
          Mitglied registrieren
        </app-action-button>
      </div>
    </div>
  </div>
</main>
