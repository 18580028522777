import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InvoiceAddress} from "../../core/network/models/InvoiceAddress";
import {PaymentMethod} from "../../core/models/PaymentMethod";
import {Router} from "@angular/router";
import {Api} from "../../core/network/Api";
import {HttpClient} from "@angular/common/http";
import {OrganizationService} from "../../core/services/organization.service";

@Component({
  selector: 'payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {

  @Input() disabled: boolean = false;
  @Output("onError") onError: EventEmitter<any> = new EventEmitter<any>();

  invoiceAddress: InvoiceAddress | null = null;
  paymentMethod: PaymentMethod | null = null;

  isLoading = false;

  constructor(private router: Router, private httpClient: HttpClient, private organizationService: OrganizationService) {
  }

  ngOnInit(): void {
    this.loadPaymentMethod();
  }

  onButtonClick(): void {
    // todo: change to dialog
    this.router.navigateByUrl("/home/settings/billing/payment-method/update");
  }

  loadPaymentMethod(): void {
    this.isLoading = true;

    Api.paymentMethod(this.httpClient, {organization_id: `${this.organizationService.selectedOrganizationId}`}, (invoiceAddress, paymentMethod) => {
      this.invoiceAddress = invoiceAddress;
      this.paymentMethod = paymentMethod;
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
      this.onError.emit();
      // todo: handle error loading
    }, () => {
    });
  }
}
