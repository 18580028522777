<main>
  <header>
    <app-header></app-header>

    <nav id="breadcrumb" *ngIf="hierarchy.length > 0">
      <ul>
        <li *ngFor="let h of hierarchy" class="clickable">
          <span [routerLink]="h.path">{{ h.title }}</span>
          <mat-icon>chevron_right</mat-icon>
        </li>
        <li><span>{{ pageTitle }}</span></li>
      </ul>
    </nav>
  </header>

  <div id="content">
    <ng-container *ngIf="!isLoading; else loading">
      <div id="content-body">
        <router-outlet></router-outlet>
      </div>

      <footer>
        <div id="footer-text">
          &copy; IT-Dienstleistungen Elias Lexl e.U. |
          <a href="https://www.smartpager.at/privacy" target="_blank">Datenschutz</a> |
          <a href="https://www.smartpager.at/terms" target="_blank">AGB</a> |
          <a href="https://www.smartpager.at/impressum" target="_blank">Impressum</a>
        </div>
        <div id="footer-logo">
          <a href="https://www.smartpager.at" target="_blank"><img src="../assets/logo_only_grey1.svg"></a>
        </div>
      </footer>
    </ng-container>

    <ng-template #loading>
      <div id="loading">
        <mat-spinner diameter="32" color="accent"></mat-spinner>
        <span>Daten werden geladen ...</span>
        <div class="clear"></div>
      </div>
    </ng-template>
  </div>


  <nav id="bottom">
    <li routerLink="/home/operations" routerLinkActive="active">
      <div class="icon"><img src="assets/icons/icn_operations.svg"></div>
      <div class="text">Einsätze</div>
    </li>
    <li routerLink="/home/events" routerLinkActive="active">
      <div class="icon"><img src="assets/icons/icn_events.svg"></div>
      <div class="text">Ereignisse</div>
    </li>
    <li routerLink="/home/members" routerLinkActive="active">
      <div class="icon"><img src="assets/icons/icn_organization.svg"></div>
      <div class="text">Mitglieder</div>
    </li>
    <li (click)="onOrganizationChangeClick($event)">
      <div class="icon"><img src="assets/icons/icn_smartpager.svg"></div>
      <div class="text">Organisation</div>
    </li>
  </nav>
</main>
