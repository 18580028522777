<main>
  <div class="body-content-centered">
    <nav class="body-content-header-nav">
      <div class="body-content-header-nav-title">Connect (Beta)</div>
    </nav>

    <div>
      <p>Automatischer Ausdruck auf einem lokalen Drucker und Anbindung an das Warn- und Alarmsystem in
        Oberösterreich.</p>
      <p><a href="https://support.smartpager.at/connect/device/" target="_blank">Dokumentation von Connect</a></p>
    </div>

    <div class="loading" *ngIf="isLoading; else content">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <ng-template #content>
      <div id="content">
        <div id="setup" *ngIf="!info?.is_active">

        </div>


        <div id="status" *ngIf="info?.is_active">
          <h2>DIENSTE</h2>
          <ul>
            <li><span class="status-indicator"
                      [ngClass]="info?.is_printer_active == null ? '': (info?.is_printer_active ? 'active' : 'inactive')"></span>
              Ausdruck
            </li>
            <li><span class="status-indicator"
                      [ngClass]="info?.is_watcher_active == null ? '' : (info?.is_watcher_active ? 'active' : 'inactive')"></span>
              Warn- und Alarmsystem<span *ngIf="info?.is_deletable"> (Bald verfügbar)</span>
            </li>
          </ul>
        </div>

        <div id="settings" *ngIf="info?.settings != null && !info?.is_deletable">
          <h2>EINSTELLUNGEN</h2>

          <h3>Anbindung Warn- und Alarmsystem</h3>
          <div class="setting">
            <div class="setting-title"><span>Verzögerung der Ausfallsbenachrichtigung</span></div>
            <div class="setting-value">
              <mat-form-field appearance="fill">
                <mat-label>Minuten</mat-label>
                <mat-select [(value)]="watcherOfflineNotificationDelay" [disabled]="isUpdatingSettings">
                  <mat-option [value]="5">5min</mat-option>
                  <mat-option [value]="10">10min</mat-option>
                  <mat-option [value]="20">20min</mat-option>
                  <mat-option [value]="30">30min (empfohlen)</mat-option>
                  <mat-option [value]="45">45min</mat-option>
                  <mat-option [value]="60">1h</mat-option>
                  <mat-option [value]="90">1h 30min</mat-option>
                  <mat-option [value]="120">2h</mat-option>
                  <mat-option [value]="180">3h</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <h3>Einsatzdatenblätter</h3>
          <div class="setting">
            <div class="setting-title"><span>Kopien</span></div>
            <div class="setting-value">
              <mat-form-field appearance="fill">
                <mat-label>Anzahl</mat-label>
                <mat-select [(value)]="alarmSheetPrintCount" [disabled]="isUpdatingSettings">
                  <mat-option [value]="0">Keine</mat-option>
                  <mat-option [value]="1">1</mat-option>
                  <mat-option [value]="2">2</mat-option>
                  <mat-option [value]="3">3</mat-option>
                  <mat-option [value]="4">4</mat-option>
                  <mat-option [value]="5">5</mat-option>
                  <mat-option [value]="6">6</mat-option>
                  <mat-option [value]="7">7</mat-option>
                  <mat-option [value]="8">8</mat-option>
                  <mat-option [value]="9">9</mat-option>
                  <mat-option [value]="10">10</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="setting">
            <div class="setting-title"><span>Stichwörter ausnehmen</span></div>
            <div class="setting-value">
              <mat-form-field appearance="fill">
                <mat-label>Ausnahmen</mat-label>
                <mat-select [(ngModel)]="alarmSheetOperationCategoryExceptions" [disabled]="isUpdatingSettings"
                            multiple>
                  <mat-option *ngFor="let oc of operationCategories" [title]="oc.description"
                              [value]="oc.id">{{ oc.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="setting">
            <div class="setting-title"><span>Vorschau Wasserentnahmestellen</span></div>
            <div class="setting-value">
              <mat-form-field appearance="fill">
                <mat-label>Anzahl</mat-label>
                <mat-select [(value)]="alarmSheetWaterSourcesPreviewCount" [disabled]="isUpdatingSettings">
                  <mat-option [value]="0">Keine</mat-option>
                  <mat-option [value]="3">3</mat-option>
                  <mat-option [value]="5">5</mat-option>
                  <mat-option [value]="10">10</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <h3>Wasserkarte.info Datenblätter</h3>
          <div class="setting">
            <div class="setting-title"><span>Kopien</span></div>
            <div class="setting-value">
              <mat-form-field appearance="fill">
                <mat-label>Anzahl</mat-label>
                <mat-select [(value)]="waterSourcesPrintCount" [disabled]="isUpdatingSettings">
                  <mat-option [value]="0">Keine</mat-option>
                  <mat-option [value]="1">1</mat-option>
                  <mat-option [value]="2">2</mat-option>
                  <mat-option [value]="3">3</mat-option>
                  <mat-option [value]="4">4</mat-option>
                  <mat-option [value]="5">5</mat-option>
                  <mat-option [value]="6">6</mat-option>
                  <mat-option [value]="7">7</mat-option>
                  <mat-option [value]="8">8</mat-option>
                  <mat-option [value]="9">9</mat-option>
                  <mat-option [value]="10">10</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="setting">
            <div class="setting-title"><span>Wasserentnahmestellen</span></div>
            <div class="setting-value">
              <mat-form-field appearance="fill">
                <mat-label>Anzahl</mat-label>
                <mat-select [(value)]="waterSourcesCount" [disabled]="isUpdatingSettings">
                  <mat-option [value]="1">1</mat-option>
                  <mat-option [value]="2">2</mat-option>
                  <mat-option [value]="3">3</mat-option>
                  <mat-option [value]="4">4</mat-option>
                  <mat-option [value]="5">5</mat-option>
                  <mat-option [value]="6">6</mat-option>
                  <mat-option [value]="7">7</mat-option>
                  <mat-option [value]="8">8</mat-option>
                  <mat-option [value]="9">9</mat-option>
                  <mat-option [value]="10">10</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="setting">
            <div class="setting-title"><span>Stichwörter ausnehmen</span></div>
            <div class="setting-value">
              <mat-form-field appearance="fill">
                <mat-label>Ausnahmen</mat-label>
                <mat-select [(ngModel)]="waterSourcesOperationCategoryExceptions" [disabled]="isUpdatingSettings"
                            multiple>
                  <mat-option *ngFor="let oc of operationCategories" [title]="oc.description"
                              [value]="oc.id">{{ oc.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <br>
          <app-action-button iconKey="done" [disabled]="isUpdatingSettings" (click)="updateSettings()">
            Einstellungen speichern
          </app-action-button>
        </div>

        <div id="controls" *ngIf="info?.settings != null && !info?.is_deletable">
          <h2>Aktionen</h2>
          <button class="btn-action btn-secondary" (click)="printTestPage()" [disabled]="!info?.is_printer_active">
            Testseite drucken
          </button>
          <br><br>
          <span *ngIf="info?.cups_url != null"><a [href]="info?.cups_url" target="_blank">Druckerverwaltung</a> (nur im internen Netzwerk verfügbar)</span>
        </div>

        <div id="actions" *ngIf="info != null">
          <button class="btn-action btn-primary" *ngIf="!info?.is_active" (click)="navigationToWebsite()">Weitere
            Informationen
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</main>
