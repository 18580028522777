<main>
  <div class="body-content-centered">
    <h1>Einsatz erstellen</h1>

    <div id="form">
      <div class="loading" *ngIf="isLoading; else content">
        <mat-spinner color="accent" diameter="32"></mat-spinner>
      </div>

      <ng-template #content>
        <div id="content">
          <mat-form-field appearance="fill">
            <mat-label>Titel</mat-label>
            <input matInput [(ngModel)]="input.title" required>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Beschreibung</mat-label>
            <textarea matInput [(ngModel)]="input.text"></textarea>
          </mat-form-field>
          <h2>Kontakt</h2>
          <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="input.contact.name">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Telefon</mat-label>
            <input matInput [(ngModel)]="input.contact.phone">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Notizen</mat-label>
            <textarea matInput [(ngModel)]="input.contact.notes"></textarea>
          </mat-form-field>
          <h2>Alarmierung</h2>
          <mat-form-field appearance="fill">
            <mat-label>Empfängerauswahl</mat-label>
            <mat-select [(ngModel)]="input.alarm.userSelectionTypeId">
              <mat-option [value]="1">Keine Empfänger</mat-option>
              <mat-option [value]="2">Sammelruf</mat-option>
            </mat-select>
          </mat-form-field>
          <h2>Adresse</h2>
          <mat-form-field appearance="fill">
            <mat-label>Name / Firma</mat-label>
            <input matInput [(ngModel)]="input.address.name">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Straße</mat-label>
            <input matInput [(ngModel)]="input.address.street">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Hausnummer</mat-label>
            <input matInput [(ngModel)]="input.address.streetNumber">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Stock</mat-label>
            <input matInput [(ngModel)]="input.address.floor">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Tür</mat-label>
            <input matInput [(ngModel)]="input.address.door">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>PLZ</mat-label>
            <input matInput [(ngModel)]="input.address.postcode">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Ort</mat-label>
            <input matInput [(ngModel)]="input.address.city">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Land</mat-label>
            <input matInput [(ngModel)]="input.address.country">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Freitext</mat-label>
            <input matInput [(ngModel)]="input.address.freeText">
          </mat-form-field>
          <h2>Erweitert</h2>
          <mat-checkbox [(ngModel)]="input.isSendingEventNotifications">Ereignis-Benachrichtigungen senden
          </mat-checkbox>
          <br>
          <mat-checkbox [(ngModel)]="input.isPrintingAlarmSheet">Datenblatt drucken</mat-checkbox>
          <br><br>

          <app-action-button [disabled]="isCreating || input.title == ''" iconKey="done" (click)="create()">
            Erstellen
          </app-action-button>
        </div>
      </ng-template>
    </div>
  </div>
</main>
