import {Component, OnInit} from '@angular/core';
import {OrganizationService} from "../../../../core/services/organization.service";
import {Router} from "@angular/router";
import {Api} from "../../../../core/network/Api";
import {AlertComponent} from "../../../../dialogs/alert/alert.component";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-business-confirmation',
  templateUrl: './business-confirmation.component.html',
  styleUrls: ['./business-confirmation.component.scss']
})
export class BusinessConfirmationComponent implements OnInit {

  isSaving = false;

  organizationName = '';

  constructor(private router: Router, private organizationService: OrganizationService, private httpClient: HttpClient, public dialog: MatDialog) {
    this.organizationName = organizationService.getSelectedOrganization()?.name ?? '';
  }

  ngOnInit(): void {
    if (this.organizationService.getSelectedOrganization()?.organization_type_id == 4) {
      this.router.navigateByUrl('/');
    }
  }

  save(isBusiness: boolean): void {
    this.isSaving = true;

    Api.organizationTypeUpdate(this.httpClient, {
      organization_id: '' + this.organizationService.selectedOrganizationId,
      is_business: isBusiness
    }, () => {
      let organization = this.organizationService.getSelectedOrganization();
      this.router.navigateByUrl('/home/settings/billing').then(() => {
        window.location.reload();
      });
    }, (error: string) => {
      console.log(error);
      switch (error) {
        default:
          this.showAlert("Unbekannter Fehler", "Unbekannter Fehler beim Speichern! Bitte versuche es später nochmal.");
      }
      this.isSaving = false;
    }, () => {
    });
  }

  private showAlert(title: string, text: string, afterClosed: (() => any) | null = null) {
    this.dialog.open(AlertComponent, {
      data: {
        title: title,
        text: text
      },
      disableClose: true
    }).afterClosed().subscribe(() => {
      if (afterClosed) afterClosed();
    });
  }
}
