import {Component, Input, OnInit} from '@angular/core';
import {DomEvent} from "leaflet";
import stopPropagation = DomEvent.stopPropagation;

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent implements OnInit {
  @Input("iconKey") iconKey: string | null = null;
  @Input("disabled") disabled: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  onClick(event: MouseEvent): void {
    if (this.disabled) {
      event.preventDefault();
      stopPropagation(event);
    }
  }
}
