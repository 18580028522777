import {Component, OnInit} from '@angular/core';
import {
  OrganizationSwitcherDialogComponent, OrganizationSwitcherDialogResult
} from "../../dialogs/organization-switcher-dialog/organization-switcher-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {OrganizationService} from "../../core/services/organization.service";
import {UserOrganization} from "../../core/models/UserOrganization";
import {UserService} from "../../core/services/user.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isSigningOut = false;

  firstName = '';

  constructor(private dialog: MatDialog, private router: Router, private organizationService: OrganizationService,
              private userService: UserService) {
  }

  ngOnInit(): void {
    this.firstName = localStorage.getItem('first_name') ?? '';
  }

  onOrganizationClick(event: MouseEvent): void {
    OrganizationSwitcherDialogComponent.open(this.dialog).subscribe((result: OrganizationSwitcherDialogResult | null) => {
      if (result?.dataChanged) {
        window.location.reload();
      }
    })
  }

  onSignOutClick(event: MouseEvent): void {
    if (this.isSigningOut) {
      console.log('Already signing out');
      return;
    }

    this.isSigningOut = true;
    this.userService.signOut(error => {
      this.isSigningOut = false;
      console.log(error);
      alert('Fehler bei der Abmeldung! Bitte versuche es später nochmal.');
    });
  }

  getSelectedOrganization(): UserOrganization | null {
    return this.organizationService.getSelectedOrganization();
  }

  isAdministrator(): boolean {
    return this.organizationService.getSelectedOrganization()?.is_administrator ?? false;
  }
}
